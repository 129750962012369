import { Component, EventEmitter, Input, Output } from '@angular/core';
import awsdk from '@bluekc/awsdk';

@Component({
  selector: 'app-pharmacy-card',
  templateUrl: './pharmacy-card.component.html',
  styleUrls: ['./pharmacy-card.component.css']
})
export class PharmacyCardComponent {

  @Input()
  public pharmacy!: awsdk.AWSDKPharmacy | null;

  @Input()
  public hideSelectButton: boolean = false;

  @Output()
  public selectPharmacy: EventEmitter<awsdk.AWSDKPharmacy> = new EventEmitter();

  public selectMe() {
    this.selectPharmacy.emit(this.pharmacy!);
  }

}
