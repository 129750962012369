<div class="row" (click)="SelectMe()">
<div class="col-one">
  <img [src]="providerImage" class="provider-photo">
</div>
<div class="col-two">
  <h1>{{Doctor.firstName}} {{Doctor.lastName}}</h1>
  <p>{{ Doctor.specialty.value}}</p>
  <p *ngIf="AvailableAppointment"><strong>{{AvailableAppointment | availabilityDate}}</strong></p>
  <p *ngIf="NoOfAvailableAppointment"><strong>{{NoOfAvailableAppointment}} appointments available</strong></p>

  <ng-container *ngIf="Doctor.waitingRoomCount === 0 && !AvailableAppointment && !NoOfAvailableAppointment;">
    <svg>
      <circle cx="10" cy="10" r="6" stroke="#598C15" stroke-width="2" fill="#A4E949" />
    </svg>
    <span class="availability">Available Now</span>
  </ng-container>

  <ng-container *ngIf="Doctor.waitingRoomCount === 1;">
    <svg>
      <circle cx="10" cy="10" r="6" stroke="#005A89" stroke-width="2" fill="#73B7DB"></circle>
    </svg>
    <span class="availability">{{Doctor.waitingRoomCount}} patients ahead of you</span>
  </ng-container>

  <ng-container *ngIf="Doctor.waitingRoomCount > 1;">
    <svg>
      <circle cx="10" cy="10" r="6" stroke="#F78D0B" stroke-width="2" fill="#FDDF93"></circle>
    </svg>
  <span class="availability">{{Doctor.waitingRoomCount}} patients ahead of you</span>
  </ng-container>
</div>

<div class="col-three">
    <button *ngIf="ShowSelectButton" class="gray-arrow">
            <img src="../../assets/icons/icn-arrow-gray.png" alt="Go" />
    </button>
</div>
</div>
<div class="clear"></div>
