<h1>The provider has canceled today’s visit. Select another provider to continue. We apologize for the inconvenience.
</h1>
<div class="row" *ngIf="doctor">
  <div class="col-one">
    <img [src]="providerImage" class="provider-photo" />
  </div>
  <div class="col-two">
    <h2>{{doctor.firstName}} {{doctor.lastName}}</h2>
  </div>
</div>
<button (click)="NextStep()" title="Select a New Provider" class="primary-button">Select a New Provider</button>
<button (click)="Dashboard()" class="secondary-button">Go to Virtual Care Dashboard</button>