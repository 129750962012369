export class SessionValues {
    public static ACCESS_TOKEN = 'accessToken' as const;
    public static REFRESH_TOKEN = 'refreshToken' as const;
    public static SDK_INSTANCE_STATE = 'sdkInstanceState' as const;
    public static SDK_AUTHENTICATION = 'sdkAuthentication' as const;
    public static SDK_LOGGED_ON_CONSUMER = 'consumer' as const;
    public static REMEMBERED_USER = 'rememberedUser' as const;
    public static UCProcess = 'uCProcess' as const;
    public static VIDEO_CREATED = 'videoCreated' as const;
    public static VISIT = 'visit' as const;
    public static LINK_LOGIC_ROLES = 'linkLogicRoles' as const;
    public static DEPENDENTS = 'dependents' as const;
    public static B2CAuth = 'b2cAuth' as const;
    public static ID_TOKEN = "id_token" as const;
}
