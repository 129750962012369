import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
 public year: string = '';
  ngOnInit(): void {
    this.year = new Date().toLocaleString();
  }

}
