import { LoggingService } from 'src/app/logging/logging.service';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
{

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public logging: LoggingService) {
    super(process, router, location, sdk, logging);
  }

  public NextStep() {
    let currentProcess = this.process.Process;
    currentProcess.privacy.Value = true;
    currentProcess.privacy.DisplayValue = 'Acknowledged';
    super.SaveAndGo(currentProcess);
  }

  openPrivacyPdf() {
    window.open('assets/pdf/NOTICE-OF-PRIVACY-PRACTICES-for-OCN-April-2021.pdf', '_blank');
  }

  public goBack() {
    let currentProcess = this.process.Process;

    if (currentProcess.vitals) {
      currentProcess.vitals.Value = false;
      super.Save(currentProcess);
    }

    super.Back();
  }

}
