<h1>Who is this visit for?</h1>

<content-loader *ngIf="Loading" viewBox="0 0 500 230"  preserveAspectRatio="xMaxYMax meet" width="311" foregroundColor="#D1D5DB">
  <svg:rect x="0" y="0" rx="3" ry="3" width="100%" height="55"/>
	<svg:rect x="0" y="75" rx="3" ry="3" width="100%" height="55" />
	<svg:rect x="0" y="150" rx="3" ry="3" width="100%" height="55"/>
</content-loader>

<div *ngFor="let dependant of Dependants">
    <button (click)="NextStep(dependant)" class="secondary-button"
        title="{{dependant.firstName}} {{dependant.lastName}}">{{dependant.firstName}} {{dependant.lastName}} (Age {{dependant.age}})</button>
</div>
<button *ngIf="NeedDependent" [disabled]="Loading" (click)="getFamilyMembers()" title="Dependent" class="link-button">Not Seeing a Dependent?</button>

<p>Due to healthcare privacy laws, dependents age 18 or older must use their own MyBlueKC account for virtual care visits. Members can create an account at MyBlueKC.com.</p>
<p>Not seeing a dependent on your policy? Please contact Blue KC Customer Service.</p>

<div class="action-items">
    <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
