
<div *ngFor="let step of ProcessStepList" class="step">
  <div class="col-one">
    <img class="icon" *ngIf="!step.isValid && step.StepName !== (process._currentStepByRoute | async)" src="https://bluekcmemberportal.azureedge.net/icons/icn-right-arrow-gray.png" alt="Go" />
    <img class="icon" *ngIf="!step.isValid && step.StepName === (process._currentStepByRoute | async)" src="https://bluekcmemberportal.azureedge.net/icons/icn-right-arrow-blue.png" alt="Go" />
    <img class="icon" *ngIf="step.isValid && step.StepName === (process._currentStepByRoute | async)" src="https://bluekcmemberportal.azureedge.net/icons/icn-right-arrow-blue.png" alt="Done" />
    <img class="icon" *ngIf="step.isValid && step.StepName !== (process._currentStepByRoute | async)" src="https://bluekcmemberportal.azureedge.net/icons/icn-checkmark-black.png" alt="Done" />
  </div>
  <div class="col-two">
    <h2 [ngClass]="{'current': step.StepName === (process._currentStepByRoute | async) }">{{step.DisplayName}}</h2>
    <p>{{step.DisplayValue}}</p>
    <p>{{step.DisplayValueLine2}}</p>
  </div>
</div>