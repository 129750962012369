<h1 class="visit-cost">Visit Cost</h1>
<content-loader *ngIf="CostLoading" viewBox="0 0 400 30" foregroundColor="#D1D5DB">
  <svg:rect x="0" y="0" rx="3" ry="3" width="140" height="24" />
</content-loader>

<p *ngIf="!CostLoading">{{VisitCost | currency}}</p>
<p>Blue KC members who received virtual care between February 21, 2024—June 3, 2024, during the Change Health Care outage, may not have been charged correctly for their visits. Amwell, our vendor partner who provides virtual care for Blue KC, will begin processing corrected charges and refunds June 3, 2024.</p>

<p><strong>What could this mean to you?</strong> This means that you may receive a refund for a visit that you were not supposed to pay for, or you may be charged for a visit you were supposed to pay for.</p>

<p><strong>Members who will receive a refund for services:</strong> If you had a visit during this timeframe, and your plan benefits do not require payment, but you were prompted to pay, Amwell will issue a refund to the credit card on file.</p>

<p><strong>Members who will be charged for services:</strong> If you had a visit during this timeframe, and your plan benefits require copay or other cost-sharing, but you were charged $0, Amwell will charge the balance due to the credit card on file.</p>
<p>You can verify these transactions via MyBlueKC.com under Virtual Care “visit history”.</p> 
<p>We apologize for the inconvenience. If you have any questions, please call the Blue KC Customer Service number on the back of your ID card.</p>
<ng-container *ngIf="this.VisitCost > 0 || CostLoading;else noCostTemplate">


  <article *ngIf="PaymentEntered">
    <h1>Payment Method</h1>

    <div *ngIf="PaymentMethodLoading">
      <content-loader viewBox="0 0 400 30" foregroundColor="#D1D5DB">
        <svg:rect x="0" y="0" rx="3" ry="3" width="200" height="24" />
      </content-loader>
      <button [disabled]="true" class="primary-button">Start Visit</button>
    </div>

    <div *ngIf="!PaymentMethodLoading">

      <app-payment-current [PaymentMethod]="SelectedPaymentMethod"></app-payment-current>

      <button *ngIf="!CardExpired" class="primary-button" (click)="NextStep()" title="Confirm">Confirm Payment & Start
        Visit</button>

      <button (click)="ConfirmCardChange()" class="{{CardExpired ? 'blue': 'secondary-button'}}"
        title="Update">{{CardExpired ?
        'Update Payment Method'
        : 'Change Payment Method'}}</button>

    </div>
  </article>

  <p class="error-text" *ngIf="ShowDeclinationError">Your card has been declined. Please verify your card information to
    continue.</p>

  <article *ngIf="!PaymentEntered && !PaymentMethodLoading" class="payment-info">
    <app-payment-info (SelectedPaymentMethod)="NewPaymentEntered($event)" (Oopsie)="RegisterOopsie()" (CardExpired)="CardExpired">
    </app-payment-info>
  </article>
</ng-container>


<ng-template #noCostTemplate>
  <ng-container *ngIf="VisitCost == 0 && IsMaMember">
    <!--Covid messages-->
    <p>At this time, Blue KC is not charging for Virtual Care visits during the current public health
      emergency.</p>
  </ng-container>
  <!--Zero cost visit-->
  <ng-container *ngIf="VisitCost == 0 && !IsMaMember">
    <h1>Payment Method</h1>
    <p>No payment is necessary for this visit.</p>
  </ng-container>
  <button  class="primary-button" [disabled]="CostLoading || CostFailed" (click)="NextStep()" title="Confirm">Confirm & Start
    Visit</button>
</ng-template>

<button (click)="goBack()" class="link-button back" title="Go Back">Go Back</button>
