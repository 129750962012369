<h1>Let's verify your mobile number.</h1>

<p *ngIf="!showBehavioralHealthText">We’ll text you when it’s time for your visit to begin. We may also use this number for follow-ups.</p>

<p *ngIf="showBehavioralHealthText">We'll share this number with your provider in case the visit gets disconnected or if any follow-up is needed.</p>

<button class="primary-button" (click)="NextStep()" title="Continue">Continue with {{CallbackNo}}</button>

<button class="secondary-button" (click)="AltNumber()" title="Use Another Number">Use Another Number</button>

<div class="action-items">
  <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
