import { Injectable } from '@angular/core';
import awsdk from '@bluekc/awsdk';
import { AmwellSDKService } from './amwell-sdkservice.service';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(private sdk: AmwellSDKService) { }

  public async UpdateLocationPreference(state: awsdk.AWSDKState): Promise<boolean> {
    const updateForm = this.sdk.createConsumerUpdateForm();
    updateForm.legalResidence = state.code;
    updateForm.legalResidenceCountryCode = state.countryCode;
    return await this.sdk.updateConsumerPreferences(updateForm);
  }
  
}
