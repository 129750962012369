import { AppInsightsService } from './shared/services/app-insights.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appInsights: AppInsightsService) {
      window.onbeforeunload = () => {
        // check if it's a refresh or a close refresh shows as 'visible'
        if (document.visibilityState === 'hidden') {
          localStorage.clear();
        }
      };
  }

  public menuOpen: Boolean = false;
  private readonly _destroying$ = new Subject<void>();

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child?.firstChild) {
            /*Since we are only setting titles at the root
             check for title data from parent before changing to the child */
            if (child?.snapshot.data['title']) {
              return child.snapshot.data['title'];
            }
            child = child.firstChild;
          }
          if (child?.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private checkAndSetActiveAccount() {
   /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
   try {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  } catch (error) {
    this.appInsights.logException(error as Error);
  }
  }

  open() {
    document.getElementById("sidenav")!.style.width = "327px";
    this.menuOpen = true;
  }

  close() {
    document.getElementById("sidenav")!.style.width = "0px";
    this.menuOpen = false;
  }
}
