import { Subscription } from 'rxjs';
import { PlanDetailService } from './../../services/plan-detail.service';
import { LinklogicService } from 'src/app/shared/services/link-logic.service';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SSOConfig } from '../../models/sso/sso-config';
import { SsoModalComponent } from '../sso-modal/sso-modal.component';
import { VcJwtManagerService } from '../../services/vc-jwt-manager.service';

@Component({
  selector: 'app-no-providers-available',
  templateUrl: './no-providers-available.component.html',
  styleUrls: ['./no-providers-available.component.css']
})
export class NoProvidersAvailableComponent implements OnDestroy {

  currentProcessRoute: string = '';
  subscription: Subscription | undefined;

  constructor(private router: Router,
    private dialog: MatDialog,
    private ssoConfig: SSOConfig,
    private linkLogicServic: LinklogicService,
    private planDetailService: PlanDetailService,
    private vcJwtService: VcJwtManagerService) {
    this.currentProcessRoute = this.router.getCurrentNavigation()?.extras.state?.proccessRoute;
  }

  tryAgain() {
    if (this.currentProcessRoute === '/urgent-care-visit/') {
      //Go to the step before because of the way the Process module restores itself the save and go would end up at doctors
      this.router.navigate([this.currentProcessRoute + '/patient']);
    } else {
      //Not sure if we will ever get here from a scheduled BH visit, but if we do we would want to send them back to scheduling
      this.router.navigate(['behavioral-health-scheduling']);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  findCareNearYou() {
    const memeCK = this.vcJwtService.MemberCK;
    this.subscription = this.planDetailService.ApiGetPlanDetails(memeCK, 'MED', 'C').subscribe(planDetail => {
      let isSpira = false;
      if (planDetail) {
        isSpira = planDetail.benefitSummaries.filter(benefit => benefit.code === 'CLNC').length > 0;
      }
      const isMA = this.linkLogicServic.isMAMember();
      this.dialog.open(SsoModalComponent, {
        width: '500px',
        data: this.ssoConfig.HealthSparqType(isSpira, isMA)
      });
    });
  }
}
