<h1>Find a Pharmacy</h1>

<div class="input-search">
  <input type="text" [(ngModel)]="search" placeholder="Search by city, state or ZIP Code" (input)="searchPipe.next($event)" />
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" />
    </svg>
  </span>
</div>
<article *ngIf="searchDetected === undefined">
  <p>Please select a pharmacy in the state you're currently located.</p>
  <p>Please note: some of these search results may be out of network.
      To maximize your savings make sure your pharmacy is in network.
  </p>
  </article>
  <ng-container *ngIf="searchResults && searchResults.length === 0">
    <p>No pharmacies found. Please try searching for a City or nearby ZIP Code to view results.</p>
  </ng-container>
<div appInfiniteScroller
scrollPerecnt="70"
immediateCallback="false"
[scrollCallback]="scrollCallback">
<ng-container *ngIf="isPharmaciesAvailable">
  <ng-container *ngFor="let result of searchResults;">
    <app-pharmacy-card [pharmacy]="result" (selectPharmacy)="SetSelectedPharmacy($event)">
    </app-pharmacy-card>
  </ng-container>
</ng-container>
</div>
<article *ngIf="showLoader">
  <content-loader *ngFor="let ghost of ghostList" viewBox="0 0 400 120" foregroundColor="#D1D5DB">
    <svg:rect x="0" y="0" rx="3" ry="3" width="300" height="24" />
    <svg:rect x="0" y="35" rx="3" ry="3" width="125" height="8" />
    <svg:rect x="0" y="50" rx="3" ry="3" width="170" height="8" />
    <svg:rect x="0" y="70" rx="3" ry="3" width="200" height="16" />
  </content-loader>
</article>

<div class="action-items">
  <button *ngIf="pharmacySelected" (click)="NextStep(selectedPharmacy!)" class="primary-button" title="Use Selected Pharmacy">Use {{selectedPharmacy!.name}}</button>
  <div class="clear"></div>
  <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
