import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ProcessServiceInterface } from '../../services/process-service.interface';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { LoggingService } from 'src/app/logging/logging.service';

@Injectable()
export abstract class ProcessBaseComponent<T extends Record<string, ProcessStep<unknown>>> {

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public logging: LoggingService) { }

  abstract NextStep(input: any): void;

  public SaveAndGo(update: T) {
    this.process.Process = update;
    let currentStepName = this.router.url.split('/').pop();
    if(currentStepName === undefined)
      throw "Unable to find current route";
    let consumer = this.sdk.getConsumer();
    this.logging.LogUserAction(this.process.ProcessRouteBase, 'User goes to next step: ' + currentStepName, consumer);
    this.router.navigate([this.process.ProcessRouteBase + this.process.GetNextStep(currentStepName)]);
  }

  public Save(update: T) {
    //for when we need to save but not navigate
    this.process.Process = update;
  }

  public Back(): void {
      this.location.back();
  }

}
