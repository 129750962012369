<div [ngClass]="{'upcomingappointmentdetail': upComingVisit, 'appointmentdetail' : !upComingVisit}" class="appointmentdetail">
  <div>
    <h2>{{appointment.assignedProvider.fullName}}</h2>
    <p class="dr-type">{{appointment.assignedProvider.specialty.value}}</p>
    <p class="date"><img src="../../assets/icons/icn-calender.png" /> {{appointmentTime | date: 'M/d/yyyy'}} <img src="../../assets/icons/icn-time.png" class="time" /> {{appointmentTime | date: 'h:mm a'}}</p>
    <ng-container *ngIf="upComingVisit">
      <div class="startappointment">
        <button (click)="startAppointment()" [disabled]="disableStart" class="primary-button">Start</button>
        <button (click)="cancel()" class="secondary-button">Cancel</button>
        <div class="clear"></div>
      </div>
      <p>You can start your appointment up to 15 minutes prior to your scheduled time. Your provider will begin the call when ready. </p>
      <p *ngIf="disableStart">Missed or cancelled appointments within 24 hours of your scheduled visit may result in a $40 fee. </p>
    </ng-container>
    <ng-container *ngIf="!upComingVisit && showCollapseButton">
      <button (click)="cancel()" class="secondary-button">Cancel</button>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="!upComingVisit">
      <button *ngIf="showCollapseButton; else showExpand" class="gray-arrow" (click)="toggle()">
        <img src="../../assets/icons/icn-collapse.png" />
      </button>
      <ng-template #showExpand>
        <button class="gray-arrow" (click)="toggle()">
          <img src="../../assets/icons/icn-expand.png" />
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>
