import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
  @Input() column1Template: any;
  @Input() column2Template: any;
  @Input() headerContent: any;
  @Input() footerContent: any;
}
