export class DeviceSetupProcessStepNames {
    public static ENABLE_CAMERA = "enable-camera" as const;
    public static TEST_CAMERA = "test-camera" as const;
    public static ENABLE_MIC = "enable-mic" as const;
    public static TEST_MIC = "test-mic" as const;
    public static TEST_AUDIO = "test-audio" as const;
    public static DISABLED = "location-disabled" as const;
    public static FINISHED = "finished" as const;
    public static BROWSER_NOT_SUPPORTED = "browser-support" as const;
}
