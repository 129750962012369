<h1>Your Pharmacy</h1>
<content-loader *ngIf="Loading" viewBox="0 0 400 75" foregroundColor="#D1D5DB">
  <svg:rect x="0" y="0" rx="3" ry="3" width="118" height="16" />
  <svg:rect x="-1" y="46" rx="3" ry="3" width="112" height="7" />
  <svg:rect x="-2" y="28" rx="3" ry="3" width="133" height="7" />
</content-loader>
<app-pharmacy-card *ngIf="PharmacySelected && !Loading" [pharmacy]="MemberPharmacy" [hideSelectButton]="true"></app-pharmacy-card>
<button (click)="NextStep()" *ngIf="PharmacySelected" title="Use this Pharmacy" class="primary-button">Use this
    Pharmacy</button>
<button (click)="SelectPharmacy()" title="Find a Pharmacy" class="secondary-button">Find a Pharmacy</button>
<p *ngIf="PharmacyInAnotherState">This pharmacy is in {{MemberPharmacy?.address?.geographicalState?.name}}, but you’re currently in {{CurrentState?.name}}. Update your pharmacy if necessary.</p>
<div class="action-items">
<button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
