<h1 *ngIf="!showBehavioralHealthText">Enter your mobile number. We’ll text you when it’s time for your visit to begin.</h1>
<h1 *ngIf="showBehavioralHealthText">Enter your mobile number. We’ll share this number with your provider in case the visit gets disconnected. </h1>
<label class="label" for="phone">Mobile Number</label>
<input type="tel" placeholder="Enter your number" name="phone" [(ngModel)]="CallbackNo" appPhoneMask
#phone="ngModel" required minlength="14" maxlength="14" pattern="^\(((?!(0))\d)(?!\1{2}\))\d{2}\) \d{3}-\d{4}$"/>
<p *ngIf="!showBehavioralHealthText">The doctor may also use this number for follow-ups.</p>
<p *ngIf="showBehavioralHealthText">The provider may also use this number for follow-ups if needed.</p>
<div class="action-items">
    <button class="primary-button" (click)="NextStep()" [disabled]="phone.invalid" title="Continue">Continue</button>
    <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
