<h1>Enter Your Payment Info</h1>
<p>Securely add your debit or credit card to continue. If you have an <abbr title="Health Savings Account">HSA</abbr> or
  <abbr title="Flex Savings Account">FSA</abbr> card, this is a qualified expense.
</p>
<form [formGroup]="PaymentFormGroup" (ngSubmit)="ConfirmCardSave()">
  <fieldset>
    <ul class='stacked'>
      <li>
        <label class="form-label">Name on Card</label>
        <div class="form-input">
          <p *ngIf="ShowNameOnCardError" class="error-text">Please enter the name as it appears on your
            card.</p>
          <input type="text" name="nameOnCard" formControlName="NameOnCard" [class.input-error]="ShowNameOnCardError"
            (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <div class="form-input">
          <label class="form-label">Card Number</label>
          <p *ngIf="ShowCardNumberError" class="error-text">The card number you entered is invalid. Please
            check your card and try
            again.</p>
          <input type="text" name="cardNumber" formControlName="CardNumber" [class.input-error]="ShowCardNumberError"
            (blur)="ValidateForm()" [textMask]="{mask: cardMaskFunction, guide: false, showMask: true}">
          </div>
      </li>
      <li class="cvv">
        <div class="form-input">
          <label class="form-label">CVV</label>
          <p *ngIf="ShowCVVError" class="error-text">The CVV you entered is incorrect. Please check your card and try
            again.</p>
          <input type="text" name="cVV" formControlName="CVV" [class.input-error]="ShowCVVError" (blur)="ValidateForm()"
            class="cvv">

          <span class="cc-images">
            <img src="../assets/logos/payment-visa.png" alt="Visa" />
            <img src="../assets/logos/payment-mastercard.png" alt="MasterCard" />
            <img src="../assets/logos/payment-discover.png" alt="Discover" />
            <img src="../assets/logos/payment-amex.png" alt="American Express" />
          </span>
          <div class="clear"></div>
        </div>

      </li>
      <li>
        <label class="form-label">Expiration Date</label>
        <p *ngIf="ShowExpirationMOError || ShowExpirationYearError" class="error-text">The expiration date you
          entered is not valid.</p>
        <span class="expiration">
          <select formControlName="ExpirationMO" name="expireMonthSelect"
            [class.input.error]="ShowExpirationMOError || CardExpired" (change)="ValidateForm()"
            class="expiration-month">
            <option *ngFor="let month of Months" [value]="month.value">{{month.name}}</option>
          </select>
          <select formControlName="ExpirationYear" name="expireYearSelect"
            [class.input.error]="ShowExpirationYearError || CardExpired" (change)="ValidateForm()"
            class="expiration-year">
            <option *ngFor="let year of Years" [value]="year">{{year}}</option>
          </select>
        </span>
      </li>
      <li>&nbsp;</li>
      <li>
        <h1>Enter Billing Address</h1>
      </li>
      <li>
        <label class="form-label" for="address1">Street Address</label>
        <div class="form-input">
          <p *ngIf="ShowAddress1Error" class="error-text">The street address for the billing address is
            required.</p>
          <input type="text" name="address1" formControlName="Address1" [class.input-error]="ShowAddress1Error"
            (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label class="form-label" for="address2">Address Line 2 (optional)</label>
        <div class="form-input">
          <input placeholder="e.g. Apartment or Suite Number" type="text" name="address2" formControlName="Address2"
            (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label class="form-label" for="city">City</label>
        <div class="form-input">
          <p *ngIf="ShowCityError" class="error-text">The city for this billing address is required.</p>
          <input type="text" name="city" formControlName="City" [class.input-error]="ShowCityError"
            (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label for="state">State</label>
        <div class="form-input">
          <p *ngIf="ShowStateError" class="error-text">The state for this billing address
            is required.</p>
          <!--State component
          <bkc-state-dropdown [ShowErrorText]="ShowStateError" StateControlName="StateControl">
          </bkc-state-dropdown>-->
          <select formControlName="StateControl" name="stateSelect" [class.input.error]="ShowStateError"
            (change)="ValidateForm()">
            <option value="none" [defaultSelected]>Select one...</option>
            <option *ngFor="let state of States" [value]="state.code">{{state.name}}</option>
          </select>
        </div>
      </li>
      <li>
        <label for="zip">ZIP Code</label>
        <div class="form-input">
          <p *ngIf="ShowZipError" class="error-text">The ZIP Code for this billing address is required.</p>
          <input type="zipcode" name="zipcode" [class.input-error]="ShowZipError" formControlName="ZipCodeControl"
            (blur)="ValidateForm()" />
        </div>
      </li>
      <li class="buttons">
        <button [disabled]="!PaymentFormGroup.valid" class="primary-button" title="Save Payment">Save Payment</button>
      </li>
    </ul>
  </fieldset>
</form>