import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.apimBase;

@Injectable({
  providedIn: 'root'
})
export class B2cMemberXrefService {

  constructor(private http: HttpClient) { }

  public GetB2cMemberXref(accessToken: string): Observable<B2CXrefResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/plain')
      .set('Authorization', 'Bearer ' + accessToken);
    return this.http.get<B2CXrefResponse>(API_URL + '/b2cmemberxref/v1/Member', { headers: headers })
      .pipe(
        catchError(err => throwError(err))
      );
  }
}

export interface B2CXrefResponse {
  b2cguid: string;
  memberBeKey: string;
}
