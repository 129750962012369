import { StorageService } from 'src/app/shared/services/storage.service';
import { MsalService } from '@azure/msal-angular';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  @Output() public sidenavToggle = new EventEmitter();

  constructor(public authService: AuthenticationService,
    public sdkService: AmwellSDKService,
    private msalService: MsalService,
    private storageService: StorageService) { }

  logout() {
    this.sdkService.logout();
    const idToken = this.storageService.IdToken;
    this.msalService.logoutRedirect({
      postLogoutRedirectUri:  location.origin,
      idTokenHint: idToken
    });
    this.storageService.clearUserSessionValues();
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
}
