<div mat-dialog-content [attr.test-id]="'ssoModal'">
    <div (click)="onNoClick()" class="window-close" alt="Close" [attr.test-id]="'ssoExitButton'">
      <img src="https://bluekcmemberportal.azureedge.net/icons/icon-modal-window-close-X.png" alt="Close Window"></div>
  <img [attr.test-id]="'ssoImage'" src="{{data.partnerLogo}}"><img>
  <p [attr.test-id]="'ssoDescription'" [innerHTML]="data.description"></p>
  <button (click)="sso()" target="_blank" class="blue-button" [attr.test-id]="'ssoActionButton'">{{data.buttonText}}</button>

</div>
<p class="note">Note: This site will open up in a new tab. If you don’t see it, please check your pop-up blocker and ensure it’s disabled. </p>
<form ngNoForm id="sso-form" action="#" method="POST"></form>
<mat-spinner *ngIf="loading"></mat-spinner>
