import { LoggingService } from 'src/app/logging/logging.service';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Subscription} from 'rxjs';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { ProcessBaseComponent } from '../process-base/process-base.component';

@Component({
  selector: 'app-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.css']
})
export class ProgressTrackerComponent
  <T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit, OnDestroy {

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public logging: LoggingService) {
    super(process, router, location, sdk, logging);
  }

  private Subscription: Subscription | undefined;
  public ProcessStepList: any[] = [];

  public ngOnInit() {
    this.Subscription = this.process._currentState.subscribe((r) => {

      let mainList = Object.keys(r).map(v => ({
        DisplayName: r[v].DisplayName,
        DisplayValue: r[v].DisplayValue,
        DisplayValueLine2: r[v].DisplayValueLine2,
        isValid: r[v].Validation(),
        StepName: v,
        Active: r[v].Active !== false
    })).filter(step => step.Active && step.DisplayName);

      this.ProcessStepList = [...mainList];
    });
  }

  public ngOnDestroy() {
    this.Subscription?.unsubscribe();
  }

  //Just here to satisfy the super
  public NextStep(input: any): void {
    super.SaveAndGo(input);
  }
}
