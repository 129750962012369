<h1>Are you currently taking any medications?</h1>
<p>(optional)</p>
<div class="input-search">
    <input type="text" placeholder="Search by medication name" [(ngModel)]="SearchText" (ngModelChange)="Search()">
    <span (click)="Search()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" />
            </svg>
    </span>        
</div>
<p *ngIf="Searching">Searching ...</p>
<p *ngIf="NoSearchResults">No medications found.</p>
<div class="medication-listing">
    <div *ngFor="let result of DisplayResults" class="med-selection">
            <p>{{result?.Medication?.displayName}}</p>
            <div *ngIf="result.Selected; then removeBlock else selectBlock"></div>
        <ng-template #selectBlock>
            <button (click)="SelectItem(result.Medication)" class="secondary-button">Select</button>
        </ng-template>
        <ng-template #removeBlock>
            <button (click)="RemoveItem(result.Medication)" class="remove">
                <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2667 5.33314H14.7333V4.19981C14.7333 3.29807 14.3751 2.43327 13.7375 1.79564C13.0999 1.15802 12.2351 0.799805 11.3333 0.799805H9.06667C8.16493 0.799805 7.30013 1.15802 6.6625 1.79564C6.02488 2.43327 5.66667 3.29807 5.66667 4.19981V5.33314H1.13333C0.832755 5.33314 0.544487 5.45254 0.331946 5.66508C0.119404 5.87763 0 6.16589 0 6.46647C0 6.76705 0.119404 7.05532 0.331946 7.26786C0.544487 7.4804 0.832755 7.59981 1.13333 7.59981H2.26667V20.0665C2.26667 20.9682 2.62488 21.833 3.2625 22.4706C3.90013 23.1083 4.76493 23.4665 5.66667 23.4665H14.7333C15.6351 23.4665 16.4999 23.1083 17.1375 22.4706C17.7751 21.833 18.1333 20.9682 18.1333 20.0665V7.59981H19.2667C19.5672 7.59981 19.8555 7.4804 20.0681 7.26786C20.2806 7.05532 20.4 6.76705 20.4 6.46647C20.4 6.16589 20.2806 5.87763 20.0681 5.66508C19.8555 5.45254 19.5672 5.33314 19.2667 5.33314ZM7.93333 4.19981C7.93333 3.89923 8.05274 3.61096 8.26528 3.39842C8.47782 3.18588 8.76609 3.06647 9.06667 3.06647H11.3333C11.6339 3.06647 11.9222 3.18588 12.1347 3.39842C12.3473 3.61096 12.4667 3.89923 12.4667 4.19981V5.33314H7.93333V4.19981ZM15.8667 20.0665C15.8667 20.3671 15.7473 20.6553 15.5347 20.8679C15.3222 21.0804 15.0339 21.1998 14.7333 21.1998H5.66667C5.36609 21.1998 5.07782 21.0804 4.86528 20.8679C4.65274 20.6553 4.53333 20.3671 4.53333 20.0665V7.59981H15.8667V20.0665Z" fill="#6B7786"/>
                </svg>
                </button>
        </ng-template>
        <div class="clear"></div>
    </div>
</div>
    <div *ngIf="Loading">
      <content-loader *ngFor="let ghost of GhostList" viewBox="0 0 400 45" foregroundColor="#D1D5DB">
        <svg:rect x="0" y="5" rx="3" ry="3" width="200" height="15" />
        <svg:rect x="325" y="0" rx="3" ry="3" width="65" height="30" />
      </content-loader>
    </div>

    <div class="clear"></div>
    <div class="action-items">
        <button (click)="NextStep()" class="primary-button" title="Continue">Continue</button>
        <div class="clear mobile"></div>
        <button (click)="Back()" class="link-button back" title="Go Back">Go Back</button>
    </div>
