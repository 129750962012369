import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { UrgentCareVisitProcessStepNames } from './../../../urgent-care-visit/process/urgent-care-visit-process-step-names';
import { BehavioralHealthVisitProcessStepNames } from './../../../behavioral-health-visit/process/behavioral-health-visit-process-step-names';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import awsdk from '@bluekc/awsdk';
import { Location } from '@angular/common';
import { ProcessStep } from '../../../processes/ProcessStep.interface';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { StorageService } from '../../services/storage.service';
import { LoggingService } from 'src/app/logging/logging.service';

@Component({
  selector: 'app-provider-declined',
  templateUrl: './provider-declined.component.html',
  styleUrls: ['./provider-declined.component.css']
})
export class ProviderDeclinedComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit {
  private visit!: awsdk.AWSDKVisit | null;
  public doctor: awsdk.AWSDKProvider | undefined;

  public get providerImage(): string {
    return this.doctor?.logoUrl ? this.doctor?.logoUrl : "../../assets/logos/provider-default-image.svg";
  }

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public storageService: StorageService,
    public sdk : AmwellSDKService,
    public logging: LoggingService) {
    super(process, router, location, sdk, logging);}

  ngOnInit(): void {
    if (this.process.Process.visit.Value) {
      this.visit = this.process.Process.visit.Value as awsdk.AWSDKVisit;
      this.doctor = this.visit.assignedProvider;
    }
  }

  public NextStep() {
    if(this.process.ProcessRouteBase.includes('behavioral-health-visit')) {
    this.router.navigate(
      ["/behavioral-health-scheduling"],
      {
        relativeTo: this.route,
        queryParams: { sourceId: this.visit?.sourceId },
      });
    } else {
      this.process.resetSteps(UrgentCareVisitProcessStepNames.DOCTORS);
      this.router.navigate([this.process.ProcessRouteBase + '/doctor-select']);
    }
  }

  public Dashboard() {
    if (this.process.ProcessRouteBase.includes('behavioral-health-visit')) {
      this.process.resetSteps(BehavioralHealthVisitProcessStepNames.DEVICE_SETUP);
    } else {
      this.process.resetSteps(UrgentCareVisitProcessStepNames.LOCATION);
    }
    this.router.navigate(['/dashboard']);
  }
}
