import { SSO } from './sso';
import { SSOTypes } from './sso-types';
import { Injectable } from '@angular/core';

@Injectable()
export class SSOConfig  {

    HealthSparqType(isSpira: boolean, isMA: boolean): SSO {
        if (isSpira) {
          return this.HealthSparq_Spira();
        } else if (isMA) {
          return this.HealthSparq_MA();
        } else {
          return this.HealthSparq();
        }
      }

    private HealthSparq(): SSO {
        const sso: SSO = {
            partnerLogo: 'https://bluekcmemberportal.azureedge.net/images/img-SSO-healthsparq.png',
            // eslint-disable-next-line max-len
            description: 'You are now leaving MyBlueKC.com to visit our partner, HealthSparq. There you can find the doctor that’s just right for you.',
            buttonText: 'Find a Doctor or Hospital',
            ssoType: SSOTypes.SSO,
            ssoValue: 'healthsparq'
        };
        return sso;
    }

    private HealthSparq_Spira(): SSO {
        const sso: SSO = {
            partnerLogo: 'https://bluekcmemberportal.azureedge.net/images/img-SSO-healthsparq.png',
            // eslint-disable-next-line max-len
            description: 'You are now leaving MyBlueKC.com to visit our partner, HealthSparq. There you can find the doctor that’s just right for you.',
            buttonText: 'Find a Doctor or Hospital',
            ssoType: SSOTypes.SSO,
            ssoValue: 'healthsparq_spira'
        };
        return sso;
    }

    private HealthSparq_MA(): SSO {
        const sso: SSO = {
            partnerLogo: 'https://bluekcmemberportal.azureedge.net/images/img-SSO-healthsparq.png',
            // eslint-disable-next-line max-len
            description: 'You are now leaving MyBlueKC.com to visit our partner, HealthSparq. There you can find the doctor that’s just right for you.',
            buttonText: 'Find a Doctor or Hospital',
            ssoType: SSOTypes.SSO,
            ssoValue: 'healthsparq_ma'
        };
        return sso;
    }

    HealthSparqEstimator(): SSO {
      const sso: SSO = {
          partnerLogo: 'https://bluekcmemberportal.azureedge.net/images/img-SSO-healthsparq.png',
          // eslint-disable-next-line max-len
          description: 'You are now leaving MyBlueKC.com to visit our partner site, HealthSparq. There you can research your costs.',
          buttonText: 'Research Costs',
          ssoType: SSOTypes.SSO,
          ssoValue: 'healthsparq'
      };
      return sso;
  }

  HealthSparqEstimator_Spira(): SSO {
    const sso: SSO = {
        partnerLogo: 'https://bluekcmemberportal.azureedge.net/images/img-SSO-healthsparq.png',
        // eslint-disable-next-line max-len
        description: 'You are now leaving MyBlueKC.com to visit our partner site, HealthSparq. There you can research your costs.',
        buttonText: 'Research Costs',
        ssoType: SSOTypes.SSO,
        ssoValue: 'healthsparq_spira'
    };
    return sso;
}
}
