import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  text: string;
  option1: string;
  option2: string;
  option1Style:String;
  option2Style:string;
  showClose:boolean;
  textAsInnerHtml: boolean;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent{

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.dialogRef.disableClose = true;
     }

  CloseDialog(): void {
    this.dialogRef.close();
  }

}
