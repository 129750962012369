import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: "[appReadMore]"
})
export class ReadMoreDirective implements OnInit {
  @Input()
  public limit: number = 100;

  private readonly readMore: string = "Read more";
  private readonly readLess: string = "Read less";

  constructor(private el: ElementRef) {
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.nodeName === "A") {
      if (event.target.innerHTML === this.readMore) {
        this.el.nativeElement.children.namedItem("more").style.display = "inline";
        event.target.innerHTML = this.readLess;
      }
      else {
        this.el.nativeElement.children.namedItem("more").style.display = "none";
        event.target.innerHTML = this.readMore;
      }
    }

  }

  ngOnInit() {
    let content = this.el.nativeElement.innerHTML;
    // Take untill the last word satisfying limit
    this.limit = content.substr(0, this.limit).lastIndexOf(" ");
    // Introduce the span for displaying more text & anchor tag
    this.el.nativeElement.innerHTML = `${content.substr(0, this.limit)}<span id='more'> ${content.substr(this.limit + 1)}</span> <a class="read-more">${this.readMore}</a>`;
    // Hide the span displaying more element
    this.el.nativeElement.children.namedItem("more").style.display = "none";
  }
}
