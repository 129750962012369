import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appRouterLink]'
})
export class RouterLinkDirective {

  constructor(
    private router: Router
  ) {
  }

  @Input() routerLink: any;

  @HostListener('click', ['$event'])
  onClick(ev: UIEvent) {
    if (this.router.url.includes(this.routerLink[0])) {
      this.router.navigate([this.router.url]);
      ev.preventDefault();
    }
  }
}
