import { ValidatorFn, AbstractControl } from '@angular/forms';
import { cvvCheck } from '../helpers/card.helper';
import { ccNumCheck } from '../helpers/cc-number.helper';

export function ccNumValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const isValid = ccNumCheck(control.value);
    return isValid ? null:  {'ccNumCheck': isValid};
  };
}

export function cvvValidator( ccControlName: string): ValidatorFn {
  return (control: AbstractControl) => {
    const ccControl = control.parent?.get(ccControlName);
    const isValid = cvvCheck(control.value, ccControl?.value);
    return isValid ? null:  {'cvvCheck': isValid};
  };
}
