<section>
    <article class="headline">
        <h1><ng-container [ngTemplateOutlet]="headerContent"></ng-container></h1>
    </article>
    <article>
        <div class="col-left">
            <ng-container [ngTemplateOutlet]="column1Template"> </ng-container>
        </div>
        <div class="col-right">
            <ng-container [ngTemplateOutlet]="column2Template"> </ng-container>
        </div>
    </article>
</section>