import { Injectable } from '@angular/core';
import awsdk from '@bluekc/awsdk';

@Injectable({
  providedIn: 'root'
})
export class DoctorsService {

  private _selectedDoctor: awsdk.AWSDKProvider = <awsdk.AWSDKProvider>{};
  // private AmwellSDKService;
  //private getProvidersWithLocation;


  public get SelectedDoctor(): awsdk.AWSDKProvider {
    return this._selectedDoctor;
  }

  public set SelectedDoctor(value: awsdk.AWSDKProvider) {
    this._selectedDoctor = value;
  }
}
