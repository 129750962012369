import { LoggingService } from './../logging/logging.service';
import { Component, OnInit } from '@angular/core';
import { AmwellSDKService } from '../shared/services/amwell-sdkservice.service';
import awsdk from '@bluekc/awsdk';
import { StorageService } from '../shared/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LinklogicService } from '../shared/services/link-logic.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public displayDate: Date | undefined;
  public hasUpcominingAppointment: boolean = false;
  public name: string = '';
  public doctor: awsdk.AWSDKProvider | undefined;
  public hasVisitInProgress = false;
  public visitInProgress!: awsdk.AWSDKVisit;
  public loadingAppointments = false;
  public isMA = false;

  constructor(
    private sdkService: AmwellSDKService,
    private snackBar: MatSnackBar,
    public storageService: StorageService,
    private router: Router,
    private logging: LoggingService,
    private linkLogicService: LinklogicService) {
  }

  ngOnInit() {
    this.isMA = this.linkLogicService.isMAMember();
    var consumer = this.sdkService.getConsumer();
    if (consumer.firstName && consumer.lastName) {
      this.name = consumer?.firstName + " " + consumer?.lastName;
    }
    this.setup(consumer);
  }

  async setup(consumer: awsdk.AWSDKConsumer) {
    try {
      if (!consumer)
      {
        return;
      }

      let dependents = await this.sdkService.getDependents();

      if (!dependents?.includes(consumer)) {
        dependents?.push(consumer);
      }

      this.loadingAppointments = true;
      let appointmentPromise: Promise<awsdk.AWSDKAppointment[]>[] = [];
      dependents.forEach(d => appointmentPromise.push(this.sdkService.getAppointments(d)));

      Promise.all(appointmentPromise).then(appointments => {
        const allAppointments : awsdk.AWSDKAppointment[] = [];
        appointments.map(y => allAppointments.push(...y));

        const appointment = allAppointments.sort((a1, a2) => {return a1.schedule.scheduledStartTime - a2.schedule.scheduledStartTime}).find(() => true);
        if (appointment) {
          this.hasUpcominingAppointment = true;
          this.doctor = appointment.assignedProvider;
          this.displayDate = new Date(appointment.schedule.scheduledStartTime);
        }
        this.loadingAppointments = false;
      }).catch(e => this.appointmentError(consumer, e as awsdk.AWSDKError));

      let visitPromise: Promise<awsdk.AWSDKVisit | awsdk.AWSDKError>[] = [];
      dependents.forEach(d => visitPromise.push(this.sdkService.findActiveVisit(d).catch(e => e)));

      Promise.all(visitPromise).then(visits => {
        this.visitInProgress = visits.filter(visit => !(visit instanceof awsdk.AWSDKError))[0] as awsdk.AWSDKVisit;
        if (this.visitInProgress) {
          this.hasVisitInProgress = true;
        }
      });
    }
    catch (error) {
      //TODO: Handle any errors
      this.snackBar.open("An error occurred loading your dashboard");
    }
  }

  public get providerImage(): string {
    return this.doctor?.logoUrl ? this.doctor?.logoUrl : "../../assets/logos/provider-default-image.svg";
  }

  public get inProgressVisitProviderImage(): string {
    return this.visitInProgress.assignedProvider?.logoUrl ? this.visitInProgress.assignedProvider?.logoUrl : "../../assets/logos/provider-default-image.svg";
  }

  private appointmentError(consumer: awsdk.AWSDKConsumer, exception: awsdk.AWSDKError) {
    this.loadingAppointments = false;
    this.logging.LogError('Dashboard', 'error calling getAppointments',exception, consumer);
    this.snackBar.open('Oops, an error has occurred. We cannot access your scheduled appointments.', 'Retry')
      .onAction().subscribe(async () => {
        this.setup(consumer);
      });
  }

  public startVisit() {
    this.storageService.visitInProgress = JSON.stringify(this.visitInProgress);
    this.router.navigate(["waiting-room"]);
  }

  public async cancelVisit() {
    const visit = await this.sdkService.cancelVisit(this.visitInProgress as awsdk.AWSDKVisit);

    if (visit) {
      this.hasVisitInProgress = false;
      this.sdkService.inActiveVisit.next(false);
    }
  }
}
