import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import awsdk from '@bluekc/awsdk';


@Component({
  selector: 'app-doctor-card',
  templateUrl: './doctor-card.component.html',
  styleUrls: ['./doctor-card.component.css']
})
export class DoctorCardComponent {
  @Input() Doctor!: awsdk.AWSDKProvider;
  @Input() ShowSelectButton = true;
  @Input() AvailableAppointment!: Date;
  @Input() NoOfAvailableAppointment!: number;

  @Output() SelectDoctor: EventEmitter<awsdk.AWSDKProvider> = new EventEmitter();

  public get providerImage(): string {
    return this.Doctor.logoUrl ? this.Doctor.logoUrl : "../../assets/logos/provider-default-image.svg";
  }

  public SelectMe() {
    this.SelectDoctor.emit(this.Doctor);
  }

}
