import { CommunicationService } from './services/communication.service';
import { B2cMemberXrefService } from './services/b2c-member-xref.service';
import { MemberB2cAuthService } from './services/member-b2c-auth.service';
import { RouterLinkDirective } from './directives/router-link.directive';
import { SsoService } from './services/sso.service';
import { SSOConfig } from './models/sso/sso-config';
import { SsoModalComponent } from './components/sso-modal/sso-modal.component';
import { InfiniteScrollerDirective } from './directives/infinite-scroller.directive';
import { ProgressTrackerComponent } from './components/progress-tracker/progress-tracker.component';
import { EmptyArrayPipe } from './pipes/empty-array.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { PatientComponent } from './components/patient/patient.component';
import { DoctorCardComponent } from './components/doctor-card/doctor-card.component';
import { DoctorProfileComponent } from './components/doctor-profile/doctor-profile.component';
import { StatesComponent } from './components/states/states.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvailabilityDatePipe } from './pipes/availability-date.pipe';
import { ReadMoreDirective } from './directives/read-more.directive';
import { CallbackNumberComponent } from './components/callback-number/callback-number.component';
import { CallbackOtherNumberComponent } from './components/callback-other-number/callback-other-number.component';
import { CurrentMedsComponent } from './components/current-meds/current-meds.component';
import { MediceneAllergiesComponent } from './components/medicene-allergies/medicene-allergies.component';
import { PharmacySearchComponent } from './components/pharmacy-search/pharmacy-search.component';
import { PharmacySelectComponent } from './components/pharmacy-select/pharmacy-select.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { PharmacyCardComponent } from './components/pharmacy-card/pharmacy-card.component';
import { TextMaskModule } from 'angular2-text-mask';
import { PaymentComponent } from './components/payment/payment.component';
import { MemberService } from './services/member.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { PaymentCurrentComponent } from './components/payment-current/payment-current.component';
import { AddresConfirmComponent } from './components/addres-confirm/addres-confirm.component';
import { VitalsComponent } from './components/vitals/vitals.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProviderDeclinedComponent } from './components/provider-declined/provider-declined.component';
import { SidenavListComponent } from './components/sidenav-list/sidenav-list.component';
import { RouterModule } from '@angular/router';
import { NoProvidersAvailableComponent } from './components/no-providers-available/no-providers-available.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LinklogicService } from './services/link-logic.service';
import { SpecialityMapPipe } from './pipes/speciality-map.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SelectLocationComponent } from './components/select-location/select-location.component';
@NgModule({
  imports: [
    CommonModule,
    ContentLoaderModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  declarations: [
    PhonePipe,
    EmptyArrayPipe,
    PhoneMaskDirective,
    InfiniteScrollerDirective,
    RouterLinkDirective,
    HeaderComponent,
    LayoutComponent,
    FooterComponent,
    PatientComponent,
    DoctorCardComponent,
    DoctorProfileComponent,
    DoctorCardComponent,
    FooterComponent,
    StatesComponent,
    AvailabilityDatePipe,
    ReadMoreDirective,
    CallbackNumberComponent,
    CallbackOtherNumberComponent,
    CurrentMedsComponent,
    MediceneAllergiesComponent,
    PharmacySearchComponent,
    PharmacySelectComponent,
    PharmacyCardComponent,
    PrivacyNoticeComponent,
    PaymentComponent,
    ProgressTrackerComponent,
    PaymentInfoComponent,
    PaymentCurrentComponent,
    AddresConfirmComponent,
    VitalsComponent,
    DialogComponent,
    SidenavListComponent,
    ProviderDeclinedComponent,
    NoProvidersAvailableComponent,
    SsoModalComponent,
    SpecialityMapPipe,
    SelectLocationComponent
  ],
  exports: [
    PhonePipe,
    EmptyArrayPipe,
    PhoneMaskDirective,
    InfiniteScrollerDirective,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    PatientComponent,
    DoctorProfileComponent,
    DoctorCardComponent,
    ReadMoreDirective,
    StatesComponent,
    CallbackNumberComponent,
    ContentLoaderModule,
    CallbackOtherNumberComponent,
    CurrentMedsComponent,
    MediceneAllergiesComponent,
    PharmacySearchComponent,
    PharmacySelectComponent,
    PrivacyNoticeComponent,
    PharmacyCardComponent,
    PaymentComponent,
    ProgressTrackerComponent,
    PaymentInfoComponent,
    PaymentCurrentComponent,
    AddresConfirmComponent,
    VitalsComponent,
    DialogComponent,
    SidenavListComponent,
    NoProvidersAvailableComponent,
    SsoModalComponent,
    SpecialityMapPipe,
    MatSnackBarModule
  ],
  providers: [
    LinklogicService,
    MemberService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    SSOConfig,
    SsoService,
    MemberB2cAuthService,
    B2cMemberXrefService,
    CommunicationService
  ]
})
export class SharedModule { }
