import { StorageService } from 'src/app/shared/services/storage.service';
import { MsalService } from '@azure/msal-angular';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {

  constructor(public authService: AuthenticationService,
    public sdkService: AmwellSDKService,
    private msalService: MsalService,
    private storageService: StorageService) { }

  Name: string = '';
  @Output() closeMenu = new EventEmitter();

  ngOnInit(): void {
    var consumer = this.sdkService.getConsumer();
    if (consumer?.firstName && consumer?.lastName) {
      this.Name = consumer?.firstName + " " + consumer?.lastName;
    }
  }

  logout() {
    this.sdkService.logout();
    const idToken = this.storageService.IdToken;
    this.msalService.logoutRedirect({
      postLogoutRedirectUri:  location.origin,
      idTokenHint: idToken
    });
    this.storageService.clearUserSessionValues();
    this.close();
  }

  close() {
    this.closeMenu.emit();
  }
}
