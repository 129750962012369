<article>
    <h1>Enter your height & weight</h1>
    <p>The required information helps your provider personalize the treatment and
        medication recommendations for patients age 19 and under.
    </p>
    <form [formGroup]="vitalsForm" (ngSubmit)="NextStep()">
        <fieldset>
            <ul>
                <li class="two-col">
                    <label for="height (feet)" class="form-label">Height (feet)</label><div class="clear"></div>
                    <p class="error-text" *ngIf="formSubmitted && heightFeetError && (vitalsForm.controls.inputFeet.errors?.required || !vitalsForm.controls.inputFeet?.valid)">This information is required for the visit.</p>
                    <select formControlName="inputFeet">
                        <option [ngValue]="0" [disabled]="true" [defaultSelected]>Select one...</option>
                        <option *ngFor="let x of heightFeet" [ngValue]="x.value">{{x.value}}</option>
                    </select>
                </li>
                <li class="two-col">
                <label for="height (inches)" class="form-label">Height (inches)</label><div class="clear"></div>
                    <p class="error-text"
                        *ngIf="formSubmitted && heightInchesError && (vitalsForm.controls.inputInch.errors?.required || !vitalsForm.controls.inputInch?.valid)">This information is required for the visit.</p>
                    <select formControlName="inputInch">
                        <option [ngValue]="0" [disabled]="true" [defaultSelected]>Select one...</option>
                        <option *ngFor="let y of heightInches" [ngValue]="y.value">{{y.value}}</option>
                    </select>
                </li>
                </ul>
                <ul class="stacked">
                <li>
                    <label for="weight" class="form-label">Weight (lbs)</label>
                    <p class="error-text"
                        *ngIf="formSubmitted && weightError && (vitalsForm.controls.inputWeight.errors?.required || !vitalsForm.controls.inputWeight?.valid)">
                        This information is required for the visit.</p>
                    <input type="text" type="number" id="inputWeight" name="inputWeight" formControlName="inputWeight"
                        placeholder="Enter your weight" />
                </li>
            </ul>

            <button class="primary-button" [disabled]="!vitalsForm.valid" type="submit">Continue</button>

    </fieldset>
    </form>
    <div class="action-items">
        <button (click)="Back()" class="link-button back" title="Go Back">Go Back</button>
        </div>
</article>
