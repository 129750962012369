
export class LinkedGroupConstants {
  public static readonly MEMBER = 60;
  public static readonly CHILDHOOD_OBESITY_PROGRAM = 2147483865;
  public static readonly BLUE_AND_U = 2147483853;
  public static readonly ACTIVE_MEDICAL_NOT_DIRECT_PAY_NOT_BLUE_AND_U = 2147483857;
  public static readonly ACTIVE_MEDICAL_DIRECT_PAY_NOT_BLUE_AND_U = 2147483856;
  public static readonly GROUP_ACA_ON_EXCHANGE = 2147483855;
  public static readonly SUBSCRIBER = 2147483859;
  public static readonly SUPRESS_SBC = 2147486977;
  public static readonly OFF_EXCHANGE = 2147483852;
  public static readonly DIRECT_PAY_ACA_ON_EXCHANGE = 2147483851;
  public static readonly HAS_DENTAL_DEDUCTIBLE = 2147483850;
  public static readonly HAS_BLUE_SELECT = 2147483854;
  public static readonly HAS_MEDICAL_DEDUCTIBLE = 2147483849;
  public static readonly HAS_DENTAL_ELIGIBILITY = 2147483847;
  public static readonly HAS_MEDICAL_ELIGIBILITY = 2147483846;
  public static readonly NEW_MEMBER_GUIDE = 2147483844;
  public static readonly F2F = 2147483841;
  public static readonly HM = 2147483895;
  public static readonly HF = 2147483840;
  public static readonly EPHIT = 2147483839;
  public static readonly UPDATE_OIC = 2147483838;
  public static readonly NON_ASO_DENTAL_NON_DIRECTPAY = 2147483837;
  public static readonly NON_ASO_MEDICAL_NON_DIRECTPAY = 2147483836;
  public static readonly ACTIVE_DENTAL = 2147483848;
  public static readonly NON_ASO_DENTAL_DIRECTPAY = 2147483835;
  public static readonly NURSELINE = 2147483858;
  public static readonly HAS_PCB = 2147483866;
  public static readonly HAS_VISION_ELIGIBILITY = 2147483897;
  public static readonly ACTIVE_VISION = 2147483896;
  public static readonly EPO = 2147484000;
  public static readonly FUTURE_EFFECTIVE_NON_DIRECT_PAY = 2147483900;
  public static readonly ACTIVE_MEDICAL_ASO = 2147483890;
  public static readonly ACTIVE_DENTAL_ASO = 2147483879;
  public static readonly ACTIVE_ASO = 2147483876;
  public static readonly VBB_ENGAGEMENT = 2147483863;
  public static readonly VBB_COST_BARRIER = 2147483862;
  public static readonly VBB_NATIONS_HOLDING = 2147483864;
  public static readonly COMPASS = 2147483860;
  public static readonly VBB_BLUE_VALLEY = 2147483861;
  public static readonly FUTURE_MEDICARE_SUPP_MBR = 2147483878;
  public static readonly CURRENT_MEDICARE_SUPP_MBR = 2147483877;
  public static readonly KS_EMPLOYER = 2147483875;
  public static readonly MO_EMPLOYER = 2147483874;
  public static readonly KS_RESIDENT = 2147483873;
  public static readonly MO_RESIDENT = 2147483872;
  public static readonly HAS_BLUE_SELECT_BASIC = 2147483871;
  public static readonly HAS_BLUE_SELECT_PLUS = 2147483870;
  public static readonly INDIVIDUAL_DIRECT_PAY_TERMED = 2147483868;
  public static readonly FUTURE_EFFECTIVE_DIRECT_PAY = 2147483867;
  public static readonly MEDSOLUTIONS_GRP = 2147483880;
  public static readonly HAS_VISION_DEDUCTIBLE = 2147483898;
  public static readonly NON_ASO_MEDICAL_DIRECTPAY = 2147483834;
  public static readonly NUETERRA = 2147483832;
  public static readonly FIRST_DOLLAR = 142;
  public static readonly HAS_DEDUCTIBLE = 141;
  public static readonly LABOR_ACCOUNTS = 140;
  public static readonly ACTIVE_MEDICAL_PPO = 139;
  public static readonly EAP = 138;
  public static readonly SUBSCRIBER_NON_SHORT_TERM_SECURITY = 137;
  public static readonly PCA = 136;
  public static readonly ACTIVE_MEDICAL_HMO = 135;
  public static readonly ACTIVE_DIRECT_PAY_SUBSCRIBER = 134;
  public static readonly ACTIVE_ADULT = 133;
  public static readonly UMB_ACCOUNT = 143;
  public static readonly HSA_ELIGIBLE = 132;
  public static readonly BCM = 130;
  public static readonly ACTIVE_PPO_ASO = 129;
  public static readonly ACTIVE_MEDICAL = 128;
  public static readonly ACTIVE_DENTAL_NON_DIRECT_PAY_SUBSCRIBER = 127;
  public static readonly ACTIVE_DENTAL_DIRECT_PAY_SUBSCRIBER = 126;
  public static readonly ACTIVE_MEDICAL_NON_DIRECT_PAY_SUBSCRIBER = 125;
  public static readonly ACTIVE_MEDICAL_DIRECT_PAY_SUBSCRIBER = 124;
  public static readonly ACTIVE_SUBSCRIBER = 123;
  public static readonly CHANGE_PCP_NON_BLUES_ENROLL = 122;
  public static readonly CHANGE_PCP_BLUES_ENROLL = 121;
  public static readonly PHARMACY = 131;
  public static readonly AHY = 2147483833;
  public static readonly BHB_ACCOUNT = 144;
  public static readonly GROUP_25 = 2147483676;
  public static readonly ELECTRONIC_ONLY_MEMBER = 2147483831;
  public static readonly PLUMBERS = 2147483814;
  public static readonly RELAY_HEALTH = 2147483811;
  public static readonly POINTS_TO_BLUE = 2147483802;
  public static readonly KUMC_Survey = 2147483801;
  public static readonly VSP = 2147483796;
  public static readonly MOTORS = 2147483772;
  public static readonly YOUR_EAP = 2147483745;
  public static readonly MY_EAP = 2147483744;
  public static readonly MISSOURI_VALLEY_LIFE_AND_HEALTH_CERTIFICATE = 2147483742;
  public static readonly CAN_SHOW_ID_CARD = 90210;
  public static readonly UPDT_CNTCT_EMPLYR = 2147483725;
  public static readonly UPDT_CNTCT_BLUES_ENROL = 2147483723;
  public static readonly UPDT_PCP_EMPLYR = 2147483722;
  public static readonly UPDT_PCP_BLUEKC = 2147483721;
  public static readonly UPDT_PCP_BLUES_ENROL = 2147483720;
  public static readonly ACTIVE_SUBSCRIBER_NON_BLUES_ENROLL = 2147483699;
  public static readonly ACTIVE_SUBSCRIBER_BLUES_ENROLL = 2147483698;
  public static readonly BLUESAVER_HSA = 2147483692;
  public static readonly MULTIPLE_ACCOUNTS = 2147483683;
  public static readonly SMALL_GROUP = 2147483678;
  public static readonly LARGE_GROUP = 2147483677;
  public static readonly UPDT_CNTCT_BLUEKC = 2147483724;
  public static readonly MEDICARE_ADVANTAGE = 2147483899;
  public static readonly AFFORDABLE_CARE_ACT = 2147483901;
  public static readonly FUTURE_EFFECTIVE_ACA = 2147483903;
}
