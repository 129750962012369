import { LoggingService } from 'src/app/logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import awsdk from '@bluekc/awsdk';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';

@Component({
  selector: 'app-pharmacy-select',
  templateUrl: './pharmacy-select.component.html',
  styleUrls: ['./pharmacy-select.component.css']
})
export class PharmacySelectComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
  implements OnInit {

  MemberPharmacy: awsdk.AWSDKPharmacy | null = null;

  PharmacySelected: boolean = true;
  PharmacyInAnotherState: boolean = false;
  CurrentState: awsdk.AWSDKState | null = null;
  Loading: boolean = false;

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public patient: awsdk.AWSDKConsumer | null,
    public snackbar: MatSnackBar,
    public logging: LoggingService) {
    super(process, router, location, sdk, logging);
  }



  public async ngOnInit() {
    if (this.patient === null)
      return;

    await this.loadPharmacies();
  }

  public async loadPharmacies() {
    try {
      this.Loading = true;
      this.PharmacySelected = false;
      let pharmacies = await this.sdk.getPatientPharmacies(this.patient!);
      if (pharmacies && pharmacies.length > 0) {
        this.MemberPharmacy = pharmacies[0];
        this.PharmacySelected = true;
        let currentProcess = this.process.Process;
        if (currentProcess['location'] && currentProcess['location'].Validation()) {
          this.CurrentState = currentProcess['location'].Value as awsdk.AWSDKState;
          this.PharmacyInAnotherState = this.CurrentState.code !== this.MemberPharmacy.address.stateCode;
        }
        else {
          this.CurrentState = this.patient!.legalResidence;
          this.PharmacyInAnotherState = this.patient!.legalResidence.code !== this.MemberPharmacy.address.stateCode;
        }
      }
      if (!this.PharmacySelected) {
        this.router.navigate([`/${this.process.ProcessRouteBase}/pharmacy/find-pharmacy`]);
      }
    }
    catch (error) {
      this.logging.LogError('PharmacyPreferencesComponent', 'Failed to get pharmacies', error as awsdk.AWSDKError, this.patient!);
      this.snackbar.open('Oops, an error has occurred. To see a list of pharmacies for virtual care visits, please try again.', 'Retry')
        .onAction().subscribe(async () => {
          this.loadPharmacies();
        });
    }
    this.Loading = false;
  }

  public async NextStep() {
    try {
      let currentProcess = this.process.Process;
      currentProcess.pharmacy.Value = this.MemberPharmacy?.name;
      currentProcess.pharmacy.DisplayValue = this.MemberPharmacy?.name;
      currentProcess.pharmacy.DisplayValueLine2 = `${this.MemberPharmacy?.address.city}, ${this.MemberPharmacy?.address.stateCode} ${this.MemberPharmacy?.address.zipCode}`;
      await this.sdk.updatePatientPharmacies(this.patient!, this.MemberPharmacy!);
      super.SaveAndGo(currentProcess);
    }
    catch (error) {
      this.logging.LogError('PharmacySelectComponent',
        'patient pharmacy failed to update',
        error as awsdk.AWSDKError, this.patient!);
      this.snackbar.open('Oops, an error has occurred. Your update could not be saved, please try again.');
    }
  }

  public SelectPharmacy() {
    this.router.navigate([`/${this.process.ProcessRouteBase}/pharmacy/find-pharmacy`]);
  }

}
