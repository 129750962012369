import { LoggingService } from 'src/app/logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import awsdk from '@bluekc/awsdk';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { BehaviorSubject, from, NEVER, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-pharmacy-search',
  templateUrl: './pharmacy-search.component.html',
  styleUrls: ['./pharmacy-search.component.css']
})
export class PharmacySearchComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
  implements OnInit, OnDestroy {
  private zip: string | undefined;
  private city: string | undefined;
  private subscriptions = new Array<Subscription>();
  public search: string = '';
  public searchDetected: boolean = false;
  public states: awsdk.AWSDKState[] = [];
  public selectedState: awsdk.AWSDKState | undefined;
  public searchResults!: Array<awsdk.AWSDKPharmacy> | undefined;
  public ghostList = [1, 2, 3, 4];
  public searchPipe = new BehaviorSubject<any>(null!);
  public selectedPharmacy: awsdk.AWSDKPharmacy | undefined;
  public pharmacySelected: boolean = false;
  public scrollCallback = () => { };
  public get isPharmaciesAvailable(): boolean {
    return this.searchResults! && this.searchResults.length > 0;
  }

  public get showLoader(): boolean {
    return this.searchDetected && this.searchResults === undefined;
  }

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public patient: awsdk.AWSDKConsumer | null = null,
    public snackbar: MatSnackBar,
    public logging: LoggingService) {
    super(process, router, location, sdk, logging);
    this.scrollCallback = this.registerSearch.bind(this);
  }

  ngOnInit() {
    this.sdk.getStates().then(s => {
      if (s) {
        this.states = s;
      }
    });
    this.selectedState = undefined;

    this.registerSearch();
  }

  public registerSearch(): void {
    this.subscriptions.push(this.searchPipe.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((event: any) => {
        const search = this.inputValidator(event);

        if (search.length > 0 && !this.searchDetected) {
          this.searchDetected = true;
        }

        this.searchResults = undefined;
        return this.searchPharmacy(search);
      })
    ).subscribe(pharmacies => {
      this.searchResults = pharmacies;
      this.searchDetected = false;
    },
      error => {
        this.searchDetected = false;
        this.searchResults = [];
        this.search = '';
        this.logging.LogError('PharmacySearchComponent',
          'pharmacy search failed',
          error, this.patient!);
        this.snackbar.open('Oops, an error has occurred. To see pharmacy information for virtual care visits, please try again.', 'Retry')
          .onAction().subscribe(async () => {
            this.registerSearch();
          });
      }
    ));
  }

  private inputValidator(event: any): string {
    if (!event) {
      return "";
    }

    const pattern = new RegExp('/^[a-zA-Z\,]{2,}$/');
    if (!pattern.test(event!.target.value)) {
      event.target.value = event.target.value.replace('/^[a-zA-Z\,]{2,}$/', '');
    }

    return event.target.value;
  }

  public searchPharmacy(search: string): Observable<awsdk.AWSDKPharmacy[] | undefined> {
    if (search.length < 2) {
      return of(undefined);
    }
    //Sanitize input
    search = search.replace(/,/g, '');
    //split on a space
    const searchTerms = search.split(' ');
    this.SetSearchParams(searchTerms);
    if (!this.zip || this.zip?.length >= 5) {
      this.logging.LogUserAction('PharmacySearchComponent', 'User searched for pharmacies with...', this.patient!,
      `City: ${this.city}, State: ${this.selectedState}, Zip: ${this.zip}`);
      return from(this.sdk.getPharmacies(this.city, this.selectedState, this.zip, 'Retail'));
    }
    return of(undefined);
  }

  public SetSelectedPharmacy(pharmacy: awsdk.AWSDKPharmacy) {
    this.selectedPharmacy = pharmacy;
    this.pharmacySelected = true;
  }

  public async NextStep(pharmacy: awsdk.AWSDKPharmacy) {
    try {
      let currentProcess = this.process.Process;
      let patient = this.patient;
      currentProcess.pharmacy.Value = pharmacy.name;
      currentProcess.pharmacy.DisplayValue = pharmacy.name;
      currentProcess.pharmacy.DisplayValueLine2 = `${pharmacy.address.city}, ${pharmacy.address.stateCode} ${pharmacy.address.zipCode}`;

      if (currentProcess.patient && currentProcess.patient.Validation()) {
        patient = currentProcess.patient.Value as awsdk.AWSDKConsumer;
      }

      await this.sdk.updatePatientPharmacies(patient!, pharmacy);
      super.SaveAndGo(currentProcess);
    } catch (error) {
      this.logging.LogError('PharmacySearchComponent',
        'patient pharmacy failed to update',
        error as awsdk.AWSDKError, this.patient!);
      this.snackbar.open('Oops, an error has occurred. Your update could not be saved, please try again.');
    }
  }

  private isValidState(inputText: string): boolean {
    return this.states.some(s => s.name.toLocaleLowerCase() === inputText.toLocaleLowerCase() || s.code.toLocaleLowerCase() === inputText.toLocaleLowerCase());
  }

  private SetSearchParams(searchTerms: Array<string>) {
    //reset defaults for each search
    this.city = undefined;
    this.zip = undefined;
    this.selectedState = undefined;

    var numeric = new RegExp('^[0-9]+$');
    //One input that's only numbers
    if (searchTerms.length === 1 && numeric.test(searchTerms[0])) {
      this.zip = searchTerms[0];
      return;
    }//One input that is clearly a state
    else if (searchTerms.length === 1 && this.isValidState(searchTerms[0])) {
      this.selectedState = this.states.find(s => s.name.toLocaleLowerCase() === searchTerms[0].toLocaleLowerCase() ||
        s.code.toLocaleLowerCase() === searchTerms[0].toLocaleLowerCase());
      return;
    } else if (searchTerms.length === 1) {
      this.city = searchTerms[0];
      return;
    }

    if (numeric.test(searchTerms[searchTerms.length - 1])) {
      //they gave us a zip but did they give us a state?
      this.zip = searchTerms[searchTerms.length - 1];
      let term = searchTerms[searchTerms.length - 2];

      if (this.isValidState(term)) {
        this.selectedState = this.states.find(s => s.name.toLocaleLowerCase() === term.toLocaleLowerCase() || s.code.toLocaleLowerCase() === term.toLocaleLowerCase());
        //check for city
        if (searchTerms[searchTerms.length - 3]) {
          searchTerms.pop();
          searchTerms.pop();
          this.city = searchTerms.join(' ');
        }
      } else {
        searchTerms.pop();
        this.city = searchTerms.join(' ');
      }
      return;
    }

    if (this.isValidState(searchTerms[searchTerms.length - 1])) {
      //the last item is a state, the other item should be a city
      let term = searchTerms[searchTerms.length - 1].toLocaleLowerCase();
      this.selectedState = this.states.find(s => s.name.toLocaleLowerCase() === term || s.code.toLocaleLowerCase() === term);
      searchTerms.pop();
      this.city = searchTerms.join(' ');
      return;
    }

    //It's probably a city if it still hasn't been defined yet
    if (!this.city) {
      this.city = searchTerms.join(' ');
      return;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

