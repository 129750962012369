<article class="home-address">
<h1>Provide your home address.</h1>
<p>Your provider will need this address for verification reasons.</p>

<form *ngIf="!AddressEntered && !Loading" [formGroup]="AddressFormGroup" (ngSubmit)="EnterAddress()">
  <fieldset>
    <ul class='stacked'><li>
        <label class="form-label" for="address1">Street Address</label>
        <div class="form-input">
          <p *ngIf="ShowAddress1Error" class="error-text">Please include your street address.</p>
          <input type="text" name="address1" formControlName="Address1"
            [class.input-error]="ShowAddress1Error" (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label class="form-label" for="address2">Address Line 2 (optional)</label>
        <div class="form-input">
          <input placeholder="e.g. Apartment or Suite Number" type="text" name="address2" formControlName="Address2" (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label class="form-label" for="city">City</label>
        <div class="form-input">
          <p *ngIf="ShowCityError" class="error-text">Please include your city.</p>
          <input  type="text" name="city" formControlName="City"
            [class.input-error]="ShowCityError" (blur)="ValidateForm()">
        </div>
      </li>
      <li>
        <label for="state">State</label>
        <div class="form-input">
          <p *ngIf="ShowStateError" class="error-text">Please select your state.</p>
          <select formControlName="StateControl" name="stateSelect" [class.input.error]="ShowStateError" (change)="ValidateForm()" >
            <option value="none" [defaultSelected]>Select one...</option>
            <option *ngFor="let state of States" [value]="state.code">{{state.name}}</option>
          </select>
        </div>
      </li>
      <li>
        <label for="zip">ZIP Code</label>
        <div class="form-input">
          <p *ngIf="ShowZipError" class="error-text">Please include your ZIP Code.</p>
          <p *ngIf="ShowInvalidZipError" class="error-text">Please enter a valid ZIP Code.</p>
          <input type="zipcode" name="zipcode" [class.input-error]="ShowZipError" formControlName="ZipCodeControl"
             (blur)="ValidateForm()"/>
        </div>
      </li>
      <li class="buttons">
        <button [disabled]="!AddressFormGroup.valid" class="primary-button" title="Continue">Continue</button>
      </li>
      </ul>
  </fieldset>
</form>
<content-loader *ngIf="Loading" viewBox="0 0 400 75" foregroundColor="#D1D5DB">
  <svg:rect x="0" y="0" rx="3" ry="3" width="118" height="16" />
  <svg:rect x="-1" y="46" rx="3" ry="3" width="112" height="7" />
  <svg:rect x="-2" y="28" rx="3" ry="3" width="133" height="7" />
</content-loader>
<div class="confirm-address" *ngIf="AddressEntered">
  <p>{{Address?.address1}}</p>
  <p>{{Address?.address2}}</p>
  <p class="final">{{Address?.city}}, {{Address?.stateCode}}  {{Address?.zipCode}}</p>
</div>

<button *ngIf="AddressEntered"  class="primary-button" title="Confirm" (click)="NextStep(Address!)">Confirm Address</button>
<button *ngIf="AddressEntered" class="secondary-button" title="Change" (click)="EnterAddress()" >Change Address</button>
<div class="action-items">
<button (click)="Back()" class="link-button back" title="Go Back">Go Back</button>
</div>
</article>
