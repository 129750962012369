import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.css']
})
export class StatesComponent {
  @Output() SelectState: EventEmitter<string> = new EventEmitter();
  @Input() selected: string = '';

  onChange(newValue: string){
    this.SelectState.emit(newValue);
  }

}
