<h1>Select your current location to talk to a provider licensed in your area.</h1>
<p>Your provider must practice in the same location you are in right now. Your location preference will be saved for future visits.</p>
<!-- TODO - Need to plugin State component(bkc-state-dropdown) -->
<label for="state">Current Location</label><div class="clear"></div>

<app-states (SelectState)="OnSelectState($event)" [selected]="StateCode"></app-states>

<div class="action-items">
    <button (click)="NextStep()" class="primary-button">Continue</button>
    <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
