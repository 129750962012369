import { LoggingService } from './../../../logging/logging.service';
import { Component, EventEmitter, OnInit, Output, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import awsdk from '@bluekc/awsdk';
import { BlueKcValidators } from '@bluekc/bkc-validators';
import { getValidationConfigFromCardNo } from '../../helpers/card.helper';
import { AmwellSDKService } from '../../services/amwell-sdkservice.service';
import { ccNumValidator, cvvValidator } from '../../validators/cc-num-validator';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {

  Address: awsdk.AWSDKAddress | null | undefined;
  @Output() SelectedPaymentMethod = new EventEmitter<awsdk.AWSDKPaymentMethod | undefined>();
  @Output() Oopsie = new EventEmitter();
  @Output() PaymentEntered = new EventEmitter<boolean>();
  @Output() CardExpired = new EventEmitter<boolean>();

  PaymentFormGroup: FormGroup;
  NameOnCard: string = '';
  CardNumber: string = '';
  CVV: string = '';
  ExpirationMO: number = 1;
  ExpirationYear: number = (new Date()).getFullYear();
  Address1: string | undefined = '';
  Address2: string | undefined = '';
  City: string | undefined = '';
  State: string | undefined = '';
  Zip: string | undefined = '';
  consumer: awsdk.AWSDKConsumer | undefined;

  States: awsdk.AWSDKState[] | undefined = [];

  Months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  Years: number[] = [];

  ShowNameOnCardError = false;
  ShowCardNumberError = false;
  ShowCVVError = false;
  ShowExpirationMOError = false;
  ShowExpirationYearError = false;
  ShowAddress1Error = false;
  ShowCityError = false;
  ShowZipError = false;
  ShowStateError = false;

  constructor(
    public sdk: AmwellSDKService,
    public logging: LoggingService
  ) {
    this.PaymentFormGroup = new FormGroup({
      'NameOnCard': new FormControl(this.NameOnCard, [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
      'CardNumber': new FormControl(this.CardNumber, [Validators.required, Validators.minLength(12), ccNumValidator()]),
      'CVV': new FormControl(this.CVV, [Validators.required, cvvValidator('CardNumber')]),
      'ExpirationMO': new FormControl(this.ExpirationMO, [Validators.required]),
      'ExpirationYear': new FormControl(this.ExpirationYear, [Validators.required]),
      'Address1': new FormControl(this.Address1, [Validators.required]),
      'Address2': new FormControl(this.Address2),
      'City': new FormControl(this.City, [Validators.required]),
      'StateControl': new FormControl(this.State, [Validators.required, BlueKcValidators.state]),
      'ZipCodeControl': new FormControl(this.Zip, [Validators.required, BlueKcValidators.zipCode])
    });
  }

  async ngOnInit(): Promise<void> {

    this.consumer = this.sdk.getConsumer();
    this.Address = this.consumer.address;
    this.PaymentFormGroup.get('Address1')?.setValue(this.Address?.address1);
    this.PaymentFormGroup.get('Address2')?.setValue(this.Address?.address2);
    this.PaymentFormGroup.get('City')?.setValue(this.Address?.city);
    this.PaymentFormGroup.get('StateControl')?.setValue(this.Address?.stateCode);
    this.PaymentFormGroup.get('ZipCodeControl')?.setValue(this.Address?.zipCode);

    this.States = await this.sdk.getStates();

    var yearsToShow = 20;
    var thisYear = (new Date()).getFullYear();
    for (var y = thisYear; y < thisYear + yearsToShow; y++) {
      this.Years.push(y);
    }

  }

  ConfirmCardSave(): void {
    if (confirm('Are you sure you want to save this payment method?')) {
      this.UpdatePayment();
    }
  }

  public async UpdatePayment() {
    if (this.PaymentFormGroup.valid) {
      let newRequest;
      try {
        newRequest = await this.sdk.getPaymentRequest();
        if (newRequest) {
          newRequest.address1 = this.PaymentFormGroup.get('Address1')?.value;
          newRequest.address2 = this.PaymentFormGroup.get('Address2')?.value;
          newRequest.city = this.PaymentFormGroup.get('City')?.value;
          newRequest.state = this.PaymentFormGroup.get('StateControl')?.value;
          newRequest.country = 'US';
          newRequest.nameOnCard = this.PaymentFormGroup.get('NameOnCard')?.value;
          newRequest.creditCardMonth = this.PaymentFormGroup.get('ExpirationMO')?.value;
          newRequest.creditCardYear = this.PaymentFormGroup.get('ExpirationYear')?.value;
          newRequest.creditCardSecCode = this.PaymentFormGroup.get('CVV')?.value;
          let ccNum = this.PaymentFormGroup.get('CardNumber')?.value;
          newRequest.creditCardNumber = ccNum.replace(/\D+/g, '');
          newRequest.creditCardZip = this.PaymentFormGroup.get('ZipCodeControl')?.value;
          let paymentMethodUpdate = await this.sdk.updatePaymentMethod(this.sdk.getConsumer(), newRequest);
          if (paymentMethodUpdate) {
            this.PaymentEntered.emit(true);
            this.SelectedPaymentMethod.emit(paymentMethodUpdate);
          }
        }
      }
      catch (exception) {
        this.logging.LogError("Payment Info", "Error calling updatePaymentMethod", exception as awsdk.AWSDKError, this.consumer!);
        this.logging.LogGenericConsumerError('Payment Info', 'Error calling updatePaymentMethod; Request sent',this.consumer!, JSON.stringify(newRequest));
        this.Oopsie.emit();
      }
    }
    else {
      this.ValidateForm();
    }
  }

  ValidateForm() {
    //refresh defaults
    this.ShowAddress1Error = false;
    this.ShowCVVError = false;
    this.ShowCardNumberError = false;
    this.ShowCityError = false;
    this.ShowExpirationMOError = false;
    this.ShowExpirationYearError = false;
    this.ShowNameOnCardError = false;
    this.ShowStateError = false;
    this.ShowZipError = false;
    this.CardExpired.emit(false);

    //validate expiration date
    let mo = this.PaymentFormGroup.get('ExpirationMO')?.value;
    let year = this.PaymentFormGroup.get('ExpirationYear')?.value;
    let today = new Date();
    let expirationDay = new Date(year, mo, 1);
    if (expirationDay < today && (this.PaymentFormGroup.get('ExpirationMO')?.dirty || this.PaymentFormGroup.get('ExpirationYear')?.dirty)) {
      this.ShowExpirationMOError = true;
      this.ShowExpirationYearError = true;
      this.CardExpired.emit(true);
    }

    if (this.PaymentFormGroup.get('Address1')?.dirty && this.PaymentFormGroup.get('Address1')?.invalid) {
      this.ShowAddress1Error = true;
    }
    if (this.PaymentFormGroup.get('CVV')?.dirty && this.PaymentFormGroup.get('CVV')?.invalid) {
      this.ShowCVVError = true;
    }
    if (this.PaymentFormGroup.get('CardNumber')?.dirty && this.PaymentFormGroup.get('CardNumber')?.invalid) {
      this.ShowCardNumberError = true;
    }
    if (this.PaymentFormGroup.get('City')?.dirty && this.PaymentFormGroup.get('City')?.invalid) {
      this.ShowCityError = true;
    }
    if (this.PaymentFormGroup.get('ExpirationMO')?.dirty && this.PaymentFormGroup.get('ExpirationMO')?.invalid) {
      this.ShowExpirationMOError = true;
    }
    if (this.PaymentFormGroup.get('ExpirationYear')?.dirty && this.PaymentFormGroup.get('ExpirationYear')?.invalid) {
      this.ShowExpirationYearError = true;
    }
    if (this.PaymentFormGroup.get('NameOnCard')?.dirty && this.PaymentFormGroup.get('NameOnCard')?.invalid) {
      this.ShowNameOnCardError = true;
    }
    if (this.PaymentFormGroup.get('StateControl')?.dirty && this.PaymentFormGroup.get('StateControl')?.invalid) {
      this.ShowStateError = true;
    }
    if (this.PaymentFormGroup.get('ZipCodeControl')?.dirty && this.PaymentFormGroup.get('ZipCodeControl')?.invalid) {
      this.ShowZipError = true;
    }
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }
}
