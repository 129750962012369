import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyArray',
  pure: false
})
export class EmptyArrayPipe implements PipeTransform {
  transform(array: any[]): boolean {
    return array?.length === 0;
  }
}
