export class UrgentCareVisitProcessStepNames {
    public static LOCATION = 'select-location' as const;
    public static DEVICE_SETUP = 'device-setup' as const;
    public static VIDEO_AND_MIC = 'video-and-mic'as const;
    public static DOCTORS = "doctors" as const;
    public static PAYMENT = "payment" as const;
    public static PATIENT = "patient" as const;
    public static TEXT_REMINDERS = "callback-number" as const;
    public static REASON = "reason" as const;
    public static PHARMACY = "pharmacy" as const;
    public static FIND_PHARMACY = "find-pharmacy" as const;
    public static ADDRESS_CONFIRM = "address-confirm" as const;
    public static VITALS = "vitals" as const;
    public static PRIVACY = "privacy" as const;
    public static ALLERGIES = "allergies" as const;
    public static MEDICATIONS = "medications" as const;
}
