import { CardBrandEnum, CardValidation } from '../models/card-validation';

const digitMask = (numDigits: number) => Array(numDigits).fill(/\d/);

export const getValidationConfigFromCardNo = (
  rawValue: string
): CardValidation | null =>
  cards.find(card => {
    const patterns = card.patterns.map(
      pattern => new RegExp(`^${pattern}`, 'g')
    );
    const matchResult = patterns
      .map(pattern => rawValue.match(pattern))
      .filter(result => result);

    return !!matchResult.length;
  }) || null;

const defaultFormat = /(\d{1,4})/g;

const defaultMask19 = [
  ...digitMask(4),
  ' ',
  ...digitMask(4),
  ' ',
  ...digitMask(4),
  ' ',
  ...digitMask(4),
  ' ',
  ...digitMask(3)
];

const defaultMask16 = [
  ...digitMask(4),
  ' ',
  ...digitMask(4),
  ' ',
  ...digitMask(4),
  ' ',
  ...digitMask(4)
];

const amexMask = [
  ...digitMask(4),
  ' ',
  ...digitMask(6),
  ' ',
  ...digitMask(5)
];

export const cards = Object.freeze([
  {
    type: CardBrandEnum.VISA,
    patterns: [4],
    format: defaultFormat,
    mask: defaultMask19,
    length: [13, 16, 19],
    cvvLength: [3],
    luhn: true
  },
  {
    type: CardBrandEnum.MASTERCARD,
    patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
    format: defaultFormat,
    mask: defaultMask16,
    length: [16],
    cvvLength: [3],
    luhn: true
  },
  {
    type: CardBrandEnum.AMERICANEXPRESS,
    patterns: [34, 37],
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    mask: amexMask,
    length: [15],
    cvvLength: [3, 4],
    luhn: true
  },
  {
    type: CardBrandEnum.DISCOVER,
    patterns: [60, 64, 65, 622],
    format: defaultFormat,
    mask: defaultMask16,
    length: [16],
    cvvLength: [3],
    luhn: true
  }
]);


export const cvvCheck = (cvv: string, ccNum: string): boolean => {
  if (!cvv.length) {
    return false;
  }
  //Check Only Numerics
  // Regex to check valid CVV number.
  let regex = new RegExp("^[0-9]{3,4}$");

  if (!regex.test(cvv)) {
    return false;
  }
  //Check For correct Length by Card Type
  let firstNum = ccNum.charAt(0);
  let expectedLengths;
  let included;
  switch (firstNum) {
    case '3':
      expectedLengths = cards.find(c => c.type === CardBrandEnum.AMERICANEXPRESS)?.cvvLength;
      included = expectedLengths?.includes(cvv.length);
      return included ? included : false;
    case '4':
      expectedLengths = cards.find(c => c.type === CardBrandEnum.VISA)?.cvvLength;
      included = expectedLengths?.includes(cvv.length);
      return included ? included : false;
    case '5':
      expectedLengths = cards.find(c => c.type === CardBrandEnum.MASTERCARD)?.cvvLength;
      included = expectedLengths?.includes(cvv.length);
      return included ? included : false;
    case '6':
      expectedLengths = cards.find(c => c.type === CardBrandEnum.DISCOVER)?.cvvLength;
      included = expectedLengths?.includes(cvv.length);
      return included ? included : false;
    default:
      return false;
  }

}


