<div id="doctor-profile">
<app-doctor-card [Doctor]="Doctor" [ShowSelectButton]="false"></app-doctor-card>
</div>

<div class="col-two">

    <h1>{{DoctorInfo?.firstName}} {{DoctorInfo?.lastName}}</h1>
    <p class="specialty">{{ DoctorInfo?.specialty?.value}}</p>

    <button (click)="NextStep()" title="Continue" class="primary-button"  [attr.disabled]="DisableNextButton ? true : null">Continue with {{Doctor?.firstName}}</button>

      <p><strong>Language Spoken</strong><br /><span>{{spokenLanguages}}</span></p>
      <p><strong>Years Experience</strong><br /><span>{{DoctorInfo?.yearsExperience}}</span></p>
      <p><strong>Education</strong><br /><span>{{DoctorInfo?.schoolName}}, {{DoctorInfo?.graduatingYear}}</span></p>
    </div>


<div class="action-items">
    <button  (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
</div>
