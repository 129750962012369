import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, pairwise, filter, exhaustMap } from 'rxjs/operators';

interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
};

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  sH: 0,
  sT: 0,
  cH: 0
};

@Directive({
  selector: '[appInfiniteScroller]'
})
export class InfiniteScrollerDirective implements AfterViewInit {

  private scrollEvent$!: Observable<unknown>;

  private userScrolledDown$: any;

  private requestOnScroll$: any;

  @Input()
  scrollCallback: (() => any) = () => { };

  @Input()
  immediateCallback: any;

  @Input()
  scrollPercent: number = 70;

  constructor(private elm: ElementRef) { }

  ngAfterViewInit() {

    this.registerScrollEvent();

    this.streamScrollEvents();

    this.requestCallbackOnScroll();

  }

  private registerScrollEvent() {

    this.scrollEvent$ = fromEvent(this.elm.nativeElement, 'scroll');

  }

  private streamScrollEvents() {
    this.userScrolledDown$ = this.scrollEvent$!.pipe(
      map((e: any): ScrollPosition => ({
        sH: e.target.scrollHeight,
        sT: e.target.scrollTop,
        cH: e.target.clientHeight
      })), pairwise(), filter((positions: any[]) => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1]),
        exhaustMap(() => { return this.scrollCallback(); })));
  }

  private requestCallbackOnScroll() {

    this.requestOnScroll$ = this.userScrolledDown$;

    if (!this.requestOnScroll$) return;

    if (this.immediateCallback !== 'false') {
      this.requestOnScroll$ = this.requestOnScroll$.startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]);
    }
  }

  private isUserScrollingDown = (positions: { sT: number; }[]) => {
    return positions[0].sT < positions[1].sT;
  }

  private isScrollExpectedPercent = (position: { sT: any; cH: any; sH: number; }) => {
    return ((position.sT + position.cH) / position.sH) > (this.scrollPercent / 100);
  }

}
