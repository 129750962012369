<div>
  <mat-dialog-content>
  <img *ngIf="data.showClose" src="https://bluekcmemberportal.azureedge.net/icons/DismissAction.svg" (click)="CloseDialog()"/>
  <h2>{{data.title}}</h2>
  <p *ngIf="!data.textAsInnerHtml">{{data.text}}</p>
  <div *ngIf="data.textAsInnerHtml" [innerHtml]="data.text"></div>
  </mat-dialog-content>
  <div  mat-dialog-actions>
  <button *ngIf="data.option2" class="{{data.option2Style}}" [mat-dialog-close]="data.option2">{{data.option2}}</button>
  <button *ngIf="data.option1" class="{{data.option1Style}}" [mat-dialog-close]="data.option1">{{data.option1}}</button>
  </div>
</div>
