import { MemberB2cAuthResponse } from './../models/member-b2c-auth-response';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import awsdk from '@bluekc/awsdk';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';

declare const window: any;

export const AMWELL_SDK = new InjectionToken<awsdk.AWSDK>('Amwell SDK', {
  providedIn: 'root',
  factory: () => new awsdk.AWSDK(),
});

@Injectable({
  providedIn: 'root',
})
export class AmwellSDKService {

  awsdkLoggerConfig = window.awsdkLoggerConfig || { name: 'AWSDKLogger', level: 'error' };
  public sdk: awsdk.AWSDK = new awsdk.AWSDK(this.awsdkLoggerConfig);

  public HasUnreadMessages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public sdkIsLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public inActiveVisit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(AMWELL_SDK) public amwellSDK: awsdk.AWSDK,
    private storageService: StorageService,
    private jwtHelper: JwtHelperService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private loggingService: LoggingService
  ) { }

  // initializes the amwell sdk object
  async initialize(configuration: awsdk.initialize_config | undefined) {
    if (this.storageService.sdkInstanceState) {

      try {
        this.sdk.restoreInstanceState(this.storageService.sdkInstanceState);
      } catch (e) {
        this.loggingService.LogGenericError('AmwellSDKService', 'failed to restore sdk instance state', { 'exception': (e as awsdk.AWSDKError).message });
      }

      this.storageService.sdkInstanceState = this.sdk.instanceState;
    } else {
      var res = await this.sdk.initialize(configuration);
      if (res === true) {
        this.storageService.sdkInstanceState = this.sdk.instanceState;
      } else {
        this.loggingService.LogGenericError('AmwellSDKService', 'failed to restore sdk instance state', { 'exception': 'Unable to initialize sdk' });
      }
    }
    if (this.storageService.accessToken) {
      this.login();
    }
  }

  public async login(): Promise<boolean> {
    try {
      const auth = JSON.parse(this.storageService.b2cAuth) as MemberB2cAuthResponse;

      if (!auth) {
        this.sdkIsLoggedIn.next(false);
        return false;
      }

      var authentication = await this.sdk.authenticationService.authenticateMutualAuthWithToken(auth.authorization.access_token);
      if(!authentication) {
        this.sdkIsLoggedIn.next(false);
        return false;
      }
      //set session values
      this.storageService.sdkAuthentication = authentication;
      this.storageService.sdkLoggedOnConsumer = {};
      this.storageService.sdkLoggedOnConsumer = authentication.consumer;
      this.storageService.sdkInstanceState = this.sdk.instanceState;

      //Get messages
      let familyArray = new Array<awsdk.AWSDKConsumer>();
      let dependents = await this.getDependents();

      familyArray.push(authentication.consumer);
      familyArray.push(...dependents);

      Promise.all(familyArray.map(familyMember => this.GetMessages(familyMember, 1))).then(res => {
        if (res.some(x => x.unread > 0))
          this.HasUnreadMessages.next(true);
      });

      this.sdkIsLoggedIn.next(true);
      return true;
    } catch (e) {
      if ((e as Error).name === 'TimeoutError') {
        this.sdkIsLoggedIn.next(true);
        return true;
      }
      else {
        this.sdkIsLoggedIn.next(false);
        return false;
      }
    }
  }

  public async ManageDisclaimer(): Promise<boolean> {

    var disclaimer = await this.getDisclaimer();
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Blue KC Virtual Care Terms of Use',
        text: disclaimer.legalText,
        option1: 'Accept',
        option2: 'Decline',
        option1Style: 'primary-button',
        option2Style: 'link-button',
        showClose: false,
        textAsInnerHtml: true
      }
    });

    var result = await dialogRef.afterClosed().toPromise();
    if (result !== 'Accept') {
      return false;
    }
    return true;
  }

  public logout() {
    var consumer = this.getConsumer();
    try {
      this.sdk.authenticationService.clearAuthentication(consumer);
    } catch (e) {
      //todo the consumer object was removed from session storage
      //auth tokens are technically still in the sdk object.
      //need to probably re initialize the sdk object here
      this.loggingService.LogError('AmwellSDKService', 'failed to clear authentication', e as awsdk.AWSDKError, consumer);
    }
    this.storageService.sdkInstanceState = this.sdk.instanceState;
  }

  //todo what needs to happen if a consumer is not restored sunccessfully here?
  //todo can this throw the same 401 exceptions as other endpoints?
  getConsumer(): awsdk.AWSDKConsumer {
    var consumerString = this.storageService.sdkLoggedOnConsumer;
    return awsdk.AWSDKFactory.restoreConsumer(consumerString || '{}');
  }

  restoreConsumer(consumerString: string): awsdk.AWSDKConsumer {
    return awsdk.AWSDKFactory.restoreConsumer(consumerString || '{}');
  }

  restoreProvider(consumerString: string): awsdk.AWSDKProviderDetails {
    return awsdk.AWSDKFactory.restoreProviderDetails(consumerString || '{}');
  }

  restoreVisit(consumerString: string): awsdk.AWSDKVisit {
    return awsdk.AWSDKFactory.restoreVisit(consumerString || '{}');
  }

  async cancelConsumerAppointments(appointment: awsdk.AWSDKAppointment, retries: number = 0): Promise<boolean> {
    try {
      return await from(this.sdk.appointmentService.cancelAppointment(appointment)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.cancelConsumerAppointments(appointment, ++retries);
      } else {
        throw e;
      }
    }
  }

  //todo what needs to happen if a authObject is not restored sunccessfully here?
  //todo can this throw the same 401 exceptions as other endpoints
  getAuthentication(): awsdk.AWSDKAuthentication {
    var authString = this.storageService.sdkAuthentication;
    return awsdk.AWSDKFactory.restoreAuthentication(authString || '{}');
  }

  //todo how do we want to handle storing and retrieving visit objects?

  //todo do we want to wrap all the functions we're doing throughout the app in a service like this one?
  //if we do that, we can then wrap all the logic of storing and retrieving visit objects etc in services like this
  //rather than letting the devs do it all manually with the sdk, it'll reduce risk of error by having it all handled at this level

  async getStates(retries: number = 0): Promise<awsdk.AWSDKState[] | undefined> {
    try {
      var countries = await this.sdk.getCountries();
      var us = countries.find(c => c.code === "US");
      return us?.states;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getStates(++retries);
      } else {
        throw e;
      }
    }
  }

  /*Consumer Service Methods */
  async getDisclaimer(retries: number = 0): Promise<awsdk.AWSDKDisclaimer> {
    try {
      const disclaimer = await this.sdk.consumerService.getRegistrationDisclaimer();
      return disclaimer;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getDisclaimer(++retries);
      } else {
        throw e;
      }
    }
  }

  async getDependents(retries: number = 0): Promise<awsdk.AWSDKConsumer[]> {
    try {
      if (!this.storageService.dependents) {
        var consumer = this.getConsumer();

        let dependents = await from(this.sdk.consumerService.getDependents(consumer)).pipe(
          take(1)
        ).toPromise();

        let uniqueDependents = new Array<awsdk.AWSDKConsumer>();

        if(dependents){
          for (const d of dependents) {
            let existsDependent = uniqueDependents.find(m => m.firstName === d.firstName && m.age === d.age);
            if(!existsDependent){
              uniqueDependents.push(d);
            }
          }
        }

        this.storageService.dependents = JSON.stringify(uniqueDependents);
        return uniqueDependents;

      }

      let restoredDependents = new Array<awsdk.AWSDKConsumer>();
      let parse = JSON.parse(this.storageService.dependents);

      parse.forEach((d: any) => {
        let depString = JSON.stringify(d.__data);
        let dep = this.restoreConsumer(depString);
        restoredDependents.push(dep);
      });

      return of(restoredDependents).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getDependents(++retries);
      } else {
        throw e;
      }
    }
  }

  getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age?.toString();
  }

  async getAppointments(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKAppointment[]> {
    try {
      var appointments = await from(this.sdk.appointmentService.getAppointments(patient)).pipe(
        take(1)
      ).toPromise();
      return appointments;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getAppointments(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getUpdatedConsumer(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKConsumer> {
    try {
      var consumer = await this.sdk.consumerService.getUpdatedConsumer(patient);
      return consumer;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getUpdatedConsumer(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async findAppointmentBySourceId(consumer: awsdk.AWSDKConsumer, sourceId: string, retries: number = 0): Promise<awsdk.AWSDKAppointment> {
    try {
      var appointments = await from(this.sdk.appointmentService.findAppointmentBySourceId(consumer, sourceId)).pipe(
        take(1)
      ).toPromise();
      return appointments;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.findAppointmentBySourceId(consumer, sourceId, ++retries);
      } else {
        throw e;
      }
    }
  }

  createConsumerUpdateForm(): awsdk.AWSDKConsumerUpdate {
    return this.sdk.consumerService.newConsumerUpdate();
  }

  async updateConsumerPreferences(consumerUpdate: awsdk.AWSDKConsumerUpdate, retries: number = 0): Promise<boolean> {
    try {
      var updatedConsumer = await this.sdk.consumerService.updateConsumer(this.getConsumer(), consumerUpdate);
      this.storageService.sdkLoggedOnConsumer = updatedConsumer;
      return true;
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updateConsumerPreferences(consumerUpdate, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getPatientAllergies(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKAllergy[]> {
    try {
      return await this.sdk.consumerService.getAllergies(patient);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPatientAllergies(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async updatePatientAllergies(patient: awsdk.AWSDKConsumer, allergies: awsdk.AWSDKAllergy[], retries: number = 0): Promise<boolean> {
    try {
      return await this.sdk.consumerService.updateAllergies(patient, allergies);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updatePatientAllergies(patient, allergies, ++retries);
      } else {
        throw e;
      }
    }
  }

  async searchMedication(patient: awsdk.AWSDKConsumer, searchText: string, retries: number = 0): Promise<awsdk.AWSDKMedicationList> {
    try {
      return await from(this.sdk.consumerService.searchMedications(patient, searchText)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.searchMedication(patient, searchText, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getPatientMedications(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKMedicationList> {
    try {
      return await from(this.sdk.consumerService.getMedications(patient)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPatientMedications(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async updatePatientMedications(patient: awsdk.AWSDKConsumer, medications: awsdk.AWSDKMedication[], retries: number = 0): Promise<boolean> {
    try {
      return await this.sdk.consumerService.updateMedications(patient, medications);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updatePatientMedications(patient, medications, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getPaymentMethod(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKPaymentMethod> {
    try {
      return await this.sdk.consumerService.getPaymentMethod(patient);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPaymentMethod(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getPaymentRequest(retries: number = 0): Promise<awsdk.AWSDKPaymentRequest> {
    try {
      return await this.sdk.consumerService.newPaymentRequest();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPaymentRequest(++retries);
      } else {
        throw e;
      }
    }
  }

  async updatePaymentMethod(patient: awsdk.AWSDKConsumer, paymentRequest: awsdk.AWSDKPaymentRequest, retries: number = 0): Promise<awsdk.AWSDKPaymentMethod> {
    try {
      return await this.sdk.consumerService.updatePaymentMethod(patient, paymentRequest);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updatePaymentMethod(patient, paymentRequest, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getShippingAddress(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKAddress> {
    try {
      return await this.sdk.consumerService.getShippingAddress(patient);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getShippingAddress(patient, retries);
      } else {
        throw e;
      }
    }
  }

  async getShippingAddressUpdate(address1: string, address2: string, city: string, state: awsdk.AWSDKState, zip: string, retries: number = 0): Promise<awsdk.AWSDKAddressUpdate> {
    try {
      return await this.sdk.consumerService.newAddressUpdate(address1, address2, city, state, zip);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getShippingAddressUpdate(address1, address2, city, state, zip, retries);
      } else {
        throw e;
      }
    }
  }

  async updateShippingAddress(patient: awsdk.AWSDKConsumer, address: awsdk.AWSDKAddressUpdate, retries: number = 0): Promise<awsdk.AWSDKAddress> {
    try {
      return await this.sdk.consumerService.updateShippingAddress(patient, address);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updateShippingAddress(patient, address, retries);
      } else {
        throw e;
      }
    }
  }

  /**Pharmacy Service */
  async getPharmacies(city?: string, state?: awsdk.AWSDKState, zip?: string, pharmacyType?: string, retries: number = 0): Promise<awsdk.AWSDKPharmacy[]> {
    try {
      return await this.sdk.pharmacyService.getPharmacies(city, state, zip, pharmacyType);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getPharmacies(city, state, zip, pharmacyType, ++retries);
      } else {
        //handle in component
        throw e;
      }
    }
  }

  async getPatientPharmacies(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKPharmacy[]> {
    try {
      return await this.sdk.pharmacyService.getPharmaciesForConsumer(patient);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPatientPharmacies(patient, ++retries);
      } else {
        //handle in component
        throw e;
      }
    }
  }

  async getPatientPrimaryPharmacy(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKPharmacy | undefined> {
    try {
      return await this.sdk.pharmacyService.getPreferredPharmacy(patient);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getPatientPrimaryPharmacy(patient, ++retries);
      } else if (e.errorCode === "noPreferredPharmacyFound") {
        return undefined;
      } else {
        throw e;
      }
    }
  }

  async removePatientPharmacy(patient: awsdk.AWSDKConsumer, pharmacy: awsdk.AWSDKPharmacy, retries: number = 0): Promise<boolean> {
    try {
      return await this.sdk.pharmacyService.deletePharmacy(patient, pharmacy);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.removePatientPharmacy(patient, pharmacy, ++retries);
        //} else if(e.errorCode === "noPreferredPharmacyFound") {
        //  return false;
      } else {
        throw e;
      }
    }
  }

  async updatePatientPharmacies(patient: awsdk.AWSDKConsumer, pharmacy: awsdk.AWSDKPharmacy, retries: number = 0): Promise<awsdk.AWSDKConsumer> {
    try {
      return await this.sdk.pharmacyService.updatePreferredPharmacy(patient, pharmacy);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updatePatientPharmacies(patient, pharmacy, ++retries);
      } else {
        //handle in component
        throw e;
      }
    }
  }

  /**Practice Service Methods */
  async getPatientPractices(patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKPracticeList> {

    try {
      return await from(this.sdk.practiceService.getPractices(patient)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getPatientPractices(patient, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getOnDemandSpecialties(patient: awsdk.AWSDKConsumer, practice: awsdk.AWSDKPractice, searchTerms?: string, retries: number = 0): Promise<awsdk.AWSDKOnDemandSpecialty[]> {
    try {
      return await this.sdk.practiceService.getOnDemandSpecialties(patient, practice, searchTerms);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getOnDemandSpecialties(patient, practice, searchTerms, ++retries);
      } else {
        throw e;
      }
    }
  }


  /**Provider Service Methods */
  async getEstimatedVisitCost(doctor: awsdk.AWSDKProviderDetails, patient: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKEstimatedVisitCost> {
    return from(this.sdk.providerService.getEstimatedVisitCost(doctor, patient)).pipe(
      catchError(async e => {
        if (await this.isAuthError(e, retries)) {
          return this.getEstimatedVisitCost(doctor, patient, ++retries);
        } else {
          throw e;
        }
      })
    ).toPromise();
  }

  async getProvidersWithLocation(onDemandSpecialty: awsdk.AWSDKOnDemandSpecialty,
    licensedInState: awsdk.AWSDKState, patient: awsdk.AWSDKConsumer, practice: awsdk.AWSDKPractice, retries: number = 0): Promise<awsdk.AWSDKProvider[]> {
    return from(this.sdk.providerService.findProviders(patient, practice, onDemandSpecialty,
      undefined, undefined, undefined, licensedInState)).pipe(
        catchError(async e => {
          if (await this.isAuthError(e, retries)) {
            return this.getProvidersWithLocation(onDemandSpecialty, licensedInState, patient, practice, ++retries);
          } else {
            throw e;
          }
        })
      ).toPromise();

  }

  async getProvidersWithDetails(provider: awsdk.AWSDKProvider, retries: number = 0): Promise<awsdk.AWSDKProviderDetails> {

    return from(this.sdk.providerService.getProviderDetails(provider)).pipe(
      catchError(async e => {
        if (await this.isAuthError(e, retries)) {
          return this.sdk.providerService.getProviderDetails(provider);
        } else {
          throw e;
        }
      })
    ).toPromise();

  }

  /**Visit Service Methods */
  async getVisitContext(patient: awsdk.AWSDKConsumer,
    providerOrSpecialty: awsdk.AWSDKProvider | awsdk.AWSDKOnDemandSpecialty, retries: number = 0): Promise<awsdk.AWSDKVisitContext> {
    try {
      return await this.sdk.visitService.getVisitContext(patient, providerOrSpecialty);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVisitContext(patient, providerOrSpecialty, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getVisitContextForAppointment(appointment: awsdk.AWSDKAppointment, retries: number = 0): Promise<awsdk.AWSDKVisitContext> {
    try {
      return await this.sdk.visitService.getVisitContextForAppointment(appointment);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVisitContextForAppointment(appointment, ++retries);
      } else {
        throw e;
      }
    }
  }

  async createOrUpdateVisit(visitContext: awsdk.AWSDKVisitContext, retries: number = 0): Promise<awsdk.AWSDKVisit> {
    try {
      return await from(this.sdk.visitService.createOrUpdateVisit(visitContext)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.createOrUpdateVisit(visitContext, ++retries);
      } else {
        throw e;
      }
    }
  }

  async cancelVisit(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisit> {
    try {
      return await from(this.sdk.visitService.cancelVisit(visit)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.cancelVisit(visit, ++retries);
      } else {
        throw e;
      }
    }
  }

  async waitForVisitCostCalc(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisit> {
    return from(this.sdk.visitService.waitForVisitCostCalculationToFinish(visit)).pipe(
      catchError(async e => {
        if (await this.isAuthError(e, retries)) {
          return this.waitForVisitCostCalc(visit, retries);
        } else {
          throw e;
        }
      })
    ).toPromise();
  }

  async getProvidersFutureAvailability(consumer: awsdk.AWSDKConsumer, practice: awsdk.AWSDKPractice, name: string, appointmentDate: Date): Promise<awsdk.AWSDKProvidersAvailability> {
    let searchCriteria = this.sdk.providerService.getNewProviderFutureAvailabilitySearchCriteria({
      practiceOrSubCategory: practice,
      timeZone: consumer.timeZone,
    });

    searchCriteria.searchTerm = name ? name : " ";
    if (appointmentDate) {
      searchCriteria.appointmentDate = appointmentDate;
    }
    try {
      return await from(this.sdk.providerService.providerFutureAvailabilitySearch(consumer, searchCriteria)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      throw e;
    }

  }

  async getProviderAvailability(consumer: awsdk.AWSDKConsumer, providerDetails: awsdk.AWSDKProviderDetails, appointmentDate: Date): Promise<awsdk.AWSDKAvailabilityList> {
    let searchCriteria = this.sdk.providerService.getNewProviderAvailabilityCriteria({
      providerDetails: providerDetails,
      timeZone: consumer.timeZone,
    });
    if (appointmentDate) {
      searchCriteria.appointmentDate = appointmentDate;
    }
    try {
      return await from(this.sdk.providerService.getProviderAvailability(consumer, searchCriteria)).pipe(
        take(1)
      ).toPromise();
    } catch (e) {
      throw e;

    }
  }

  async findFirstAvailable(visitInfo: awsdk.AWSDKVisit | awsdk.AWSDKVisitContext, retries: number = 0): Promise<awsdk.AWSDKVisit | awsdk.AWSDKVisitContext> {
    try {
      return await this.sdk.visitService.findFirstAvailable(visitInfo);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.findFirstAvailable(visitInfo, ++retries);
      } else {
        throw e;
      }
    }
  }

  async scheduleAppointment(consumer: awsdk.AWSDKConsumer, provider: awsdk.AWSDKProvider, appointmentDate: Date, retries: number = 0): Promise<boolean> {
    try {
      const options = { provider: provider, appointmentDate: appointmentDate };
      return await from(this.sdk.appointmentService.schedule(consumer, options)).pipe(
        take(1)
      ).toPromise();
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.scheduleAppointment(consumer, provider, appointmentDate, ++retries);
      } else {
        throw e;
      }
    }
  }

  async startVisit(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisit> {

    try {
      return await this.sdk.visitService.startVisit(visit);
    } catch (e: any) {

      if (await this.isAuthError(e, retries)) {
        return this.startVisit(visit, ++retries);
      } else {
        throw e;
      }
    }
  }

  async visitReports(patient: awsdk.AWSDKConsumer, options?: any, retries: number = 0): Promise<awsdk.AWSDKPaginatedVisitReports> {
    try {
      return await this.sdk.consumerService.searchVisitReports(patient, options);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.visitReports(patient, ++retries);
      } else {
        throw e;
      }
    }

  }

  async getVisitReportPDF(patient: awsdk.AWSDKConsumer, visitReport: awsdk.AWSDKVisitReport, retries: number = 0): Promise<Blob> {
    try {
      return await this.sdk.consumerService.getVisitReportPDF(patient, visitReport);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVisitReportPDF(patient, visitReport, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getPostVisitFollowUpItemPDF(patient: awsdk.AWSDKConsumer, visitReport: awsdk.AWSDKPostVisitFollowUpItem, retries: number = 0): Promise<Blob> {
    try {
      return await this.sdk.consumerService.getPostVisitFollowUpItemPDF(patient, visitReport);
    } catch (e) {
      if (retries < 3 && await this.handleAuthError(e) === true) {
        return this.getPostVisitFollowUpItemPDF(patient, visitReport, ++retries);
      } else {
        throw e;
      }
    }
  }

  async endVisit(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisit> {
    try {
      return await this.sdk.visitService.endVisit(visit);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.endVisit(visit, ++retries);
      } else {
        throw e;
      }
    }
  }

  async createVideoContext(config: any, retries: number = 0): Promise<awsdk.AWSDKVideoContext> {
    try {
      return this.sdk.visitService.createVideoContext(config);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.createVideoContext(config, ++retries);
      } else {
        throw e;
      }
    }
  }

  async startWebRTCVisit(visit: awsdk.AWSDKVisit, videoContext: awsdk.AWSDKVideoContext, retries: number = 0): Promise<awsdk.AWCoreSDKVideoConsole> {
    try {
      return await this.sdk.visitService.startWebRTCVisit(visit, videoContext);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.startWebRTCVisit(visit, videoContext, ++retries);
      } else {
        throw e;
      }
    }
  }

  async getVisitSummaryDetails(patient: awsdk.AWSDKConsumer, visitReport: awsdk.AWSDKVisitReport, retries: number = 0): Promise<awsdk.AWSDKVisitReportDetail> {
    try {
      return await this.sdk.consumerService.getVisitReportDetail(patient, visitReport);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVisitSummaryDetails(patient, visitReport, ++retries);
      } else {
        throw e;
      }
    }
  }

  // async findActiveVisit(consumer: awsdk.AWSDKConsumer): Promise<awsdk.AWSDKVisit | null> {
  //   return await this.sdk.visitService.findActiveVisit(consumer);
  // }

  async getVisitSummary(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisitSummary> {
    try {
      return await this.sdk.visitService.getVisitSummary(visit);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVisitSummary(visit, ++retries);
      } else {
        throw e;
      }
    }
  }

  async addFeedback(visitOrVisitSummary: awsdk.AWSDKVisitSummary, question: string, answer: string, retries: number = 0): Promise<boolean> {
    try {
      return await this.sdk.visitService.addFeedback(visitOrVisitSummary, question, answer);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.addFeedback(visitOrVisitSummary, question, answer, ++retries);
      } else {
        throw e;
      }
    }
  }

  async addRating(visitSummary: awsdk.AWSDKVisitSummary, providerRating: number, visitRating: number, retries: number = 0): Promise<awsdk.AWSDKVisitSummary> {
    try {
      return await this.sdk.visitService.addRating(visitSummary, providerRating, visitRating);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.addRating(visitSummary, providerRating, visitRating, ++retries);
      } else {
        throw e;
      }
    }
  }

  async waitForVisitToFinish(visit: awsdk.AWSDKVisit, retries: number = 0): Promise<awsdk.AWSDKVisit> {
    try {
      return await this.sdk.visitService.waitForVisitToFinish(visit);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.waitForVisitToFinish(visit, ++retries);
      } else {
        throw e;
      }
    }
  }

  async registerDependent(parent: awsdk.AWSDKConsumer, dependentRegistration: awsdk.AWSDKDependentRegistration, retries: number = 0): Promise<awsdk.AWSDKConsumer> {
    try {
      return await this.sdk.consumerService.registerDependent(parent, dependentRegistration);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.registerDependent(parent, dependentRegistration, ++retries);
      } else {
        throw e;
      }
    }
  }

  async newDependentRegistration(retries: number = 0): Promise<awsdk.AWSDKDependentRegistration> {
    try {
      return this.sdk.consumerService.newDependentRegistration();
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.newDependentRegistration(++retries);
      } else {
        throw e;
      }
    }
  }

  async getVitals(consumer: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKVitals> {
    try {
      return await this.sdk.consumerService.getVitals(consumer);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.getVitals(consumer, ++retries);
      } else {
        throw e;
      }
    }
  }

  async updateVitals(consumer: awsdk.AWSDKConsumer, vitals: awsdk.AWSDKVitals, retries: number = 0): Promise<awsdk.AWSDKConsumer> {
    try {
      return await this.sdk.consumerService.updateVitals(consumer, vitals);
    } catch (e) {
      if (await this.isAuthError(e, retries)) {
        return this.updateVitals(consumer, vitals, ++retries);
      } else {
        throw e;
      }
    }
  }

  async findActiveVisit(consumer: awsdk.AWSDKConsumer, retries: number = 0): Promise<awsdk.AWSDKVisit | awsdk.AWSDKError> {
    try {
      return await this.sdk.visitService.findActiveVisit(consumer);
    } catch (e: any) {
      if (retries < 3 && await this.handleAuthError(e) === true) {
        return this.findActiveVisit(consumer, ++retries);
      }
      else {
        if (e.errorCode !== awsdk.AWSDKErrorCode.visitNotFound) {
          this.logout();
        }
        throw e;
      }
    }
  }

  private async handleAuthError(e: any): Promise<Boolean> {

    //user token has expired and needs to be refreshed
    if (e.errorCode === awsdk.AWSDKErrorCode.authenticationSessionExpired) {
      var accessToken = this.storageService.accessToken;
      var refreshToken = this.storageService.refreshToken;
      if (this.jwtHelper.isTokenExpired(accessToken ? accessToken : undefined)) {
        //accessToken is expired, we need to refresh it
        //checking if refresh token is expired
        if (!refreshToken) {
          this.logout();
        } else {
          //refresh token is good to go, time to refresh the accessToken
          if (await this.authService.refreshToken(refreshToken).toPromise() === true) {
            //successfully updated access token, time to login to amwell
            return await this.login();
          }
          //unable to refresh the access token
          return false;
        }
      } else {
        // accessToken is good to go, let's refresh with amwell
        return await this.login();
      }
    }
    //todo add more errorHandlers
    return false;
  }


  public async isAuthError(e: any, retries: number): Promise<boolean> {
    if (e.errorCode === awsdk.AWSDKErrorCode.authenticationSessionExpired || e.errorCode === awsdk.AWSDKErrorCode.consumerNotAuthenticated) {
      return retries < 3 && await this.handleAuthError(e) === true;
    }
    return false;
  }

  public async getContacts(consumer: awsdk.AWSDKConsumer, retries = 0): Promise<awsdk.AWSDKSecureMessageContact[]> {
    try {
      return this.sdk.secureMessageService.getContacts(consumer);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getContacts(consumer, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async sendMessage(consumer: awsdk.AWSDKConsumer, messageDraft: awsdk.AWSDKMessageDraft, retries = 0): Promise<boolean> {
    try {
      return this.sdk.secureMessageService.sendMessage(consumer, messageDraft);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.sendMessage(consumer, messageDraft, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async getNewMessageDraft(retries = 0): Promise<awsdk.AWSDKNewMessageDraft> {
    try {
      return this.sdk.secureMessageService.getNewMessageDraft();
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getNewMessageDraft(++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async newUploadAttachment(retries = 0): Promise<awsdk.AWSDKUploadAttachment> {
    try {
      return this.sdk.secureMessageService.newUploadAttachment();
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.newUploadAttachment(++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async getTopicTypes(retries = 0): Promise<awsdk.AWSDKTopicType[]> {
    try {
      return this.sdk.getTopicTypes();
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getTopicTypes(++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async getSystemConfiguration(retries = 0): Promise<awsdk.AWSDKSystemConfiguration> {
    try {
      return this.sdk.getSystemConfiguration();
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getSystemConfiguration(++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async GetMessages(consumer: awsdk.AWSDKConsumer, maxResults?: number, retries = 0): Promise<awsdk.AWSDKInbox> {
    try {
      return this.sdk.secureMessageService.getInboxMessages(consumer, undefined, maxResults, undefined);
    } catch (e: any) {
      if (await this.isAuthError(e, retries) === true) {
        return this.GetMessages(consumer, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async getMessageDetail(consumer: awsdk.AWSDKConsumer, message: awsdk.AWSDKSecureMessage, retries = 0): Promise<awsdk.AWSDKDetailedMessage> {
    try {
      return this.sdk.secureMessageService.getMessageDetail(consumer, message);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.getMessageDetail(consumer, message, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async readMessage(consumer: awsdk.AWSDKConsumer, message: awsdk.AWSDKInboxMessage, retries = 0): Promise<awsdk.AWSDKDetailedMessage> {
    try {
      return this.sdk.secureMessageService.updateMessageRead(consumer, message);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.readMessage(consumer, message, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async GetSentMessages(consumer: awsdk.AWSDKConsumer, retries = 0): Promise<awsdk.AWSDKSentMessages> {
    try {
      return this.sdk.secureMessageService.getSentMessages(consumer);
    } catch (e: any) {
      if (await this.isAuthError(e, retries)) {
        return this.GetSentMessages(consumer, ++retries);
      }
      else {
        throw e;
      }
    }
  }

  public async RemoveMessage(consumer: awsdk.AWSDKConsumer, message: awsdk.AWSDKSecureMessage, retries = 0): Promise<awsdk.AWSDKDetailedMessage> {
    try {
      return this.sdk.secureMessageService.removeMessage(consumer, message);
    } catch (e: any) {
      if (await this.isAuthError(e, retries))
        return this.RemoveMessage(consumer, message, ++retries);
      throw e;
    }
  }

  public async GetAttachment(consumer: awsdk.AWSDKConsumer, attachment: awsdk.AWSDKAttachment, retries = 0): Promise<Blob> {
    try {
      return this.sdk.secureMessageService.getAttachment(consumer, attachment);
    } catch (e: any) {
      if (await this.isAuthError(e, retries))
        return this.GetAttachment(consumer, attachment, ++retries);
      throw e;
    }
  }
}
