import { LoggingService } from 'src/app/logging/logging.service';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import awsdk from '@bluekc/awsdk';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';

@Component({
  selector: 'app-medicene-allergies',
  templateUrl: './medicene-allergies.component.html',
  styleUrls: ['./medicene-allergies.component.css'],
})
export class MediceneAllergiesComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
  implements OnInit {
  public MemberHasMedAllergies: boolean = false;

  public SelectedMedAllergies: Array<string> = [];

  public MedAllergies: Array<awsdk.AWSDKAllergy> = [];

  Noknown: awsdk.AWSDKAllergy | undefined;
  public loading: boolean = true;

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdkService: AmwellSDKService,
    private patient: awsdk.AWSDKConsumer | null,
    public logging: LoggingService
  ) {
    super(process, router, location, sdkService, logging);
  }

  async ngOnInit() {

    if (this.patient === null)
      return;

    try {
      let res = await this.sdkService.getPatientAllergies(this.patient);
      if (res) {
        this.Noknown = res.find(m => m.name.includes("No Known Drug Allergies"));
        this.MedAllergies = res.filter((med) => {
          return med != this.Noknown;
        });
        this.loading = false;
      }
    }
    catch (error) {
      //TODO
      console.log("Failed to get patient allergies: " + error);
    }

  }

  public async NextStep() {
    try {
      if (this.SelectedMedAllergies.toString() === '') {
        this.SelectedMedAllergies.push('none');
        this.Noknown!.isCurrent = true;
        this.MedAllergies.push(this.Noknown!);
      }

      let currentProcess = this.process.Process;

      if(this.patient !== null) {
        let updated = await this.sdkService.updatePatientAllergies(this.patient, this.MedAllergies);
        currentProcess.allergies.Value = updated;
        if(this.MedAllergies.length > 0)
        {
          currentProcess.allergies.DisplayValue = this.MedAllergies.filter(m => m.isCurrent).map(m => m.name).join('; ');
        }
        else{
          currentProcess.allergies.DisplayValue = "None";
        }
      }

      super.SaveAndGo(currentProcess);
    }
    catch (error) {
      //TODO handle errors
      console.log("Failed to update patient allergies: " + error);
    }
  }

  public ShowSelect() {
    this.MemberHasMedAllergies = true;
  }

  public async onNativeChange(medication: awsdk.AWSDKAllergy, e: any) {
    let index = this.MedAllergies.findIndex(a => a === medication);
    this.MedAllergies[index].isCurrent = !this.MedAllergies[index].isCurrent;
    if (e.target.checked) {
      this.SelectedMedAllergies.push(medication.name);
      return;
    }

    this.SelectedMedAllergies = this.SelectedMedAllergies.filter(
      (value) => value !== medication.name
    );

    let currentProcess = this.process.Process;
    let patient = currentProcess.patient as ProcessStep<awsdk.AWSDKConsumer>;
    let updated = await this.sdkService.updatePatientAllergies(patient.Value!, this.MedAllergies);

    currentProcess.allergies.Value = updated;
  }
}
