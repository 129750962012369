import { LoggingService } from './../../logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import awsdk from '@bluekc/awsdk';
import { AmwellSDKService } from '../../shared/services/amwell-sdkservice.service';
import { Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.css']
})
export class AppointmentCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public appointment!: awsdk.AWSDKAppointment;

  @Input()
  public upComingVisit = false;

  private remainingTime!: number;
  private subscriptions = new Array<Subscription>();

  public showCollapseButton = false;
  public everySecond: Observable<number> = timer(0, 1000);
  public appointmentTime!: Date;

  public get disableStart(): boolean {
    return this.remainingTime > 15;
  }

  constructor(public sdk: AmwellSDKService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private logging: LoggingService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    const upComingVisit: boolean = changes["upComingVisit"].currentValue;

    if (upComingVisit) {
      this.subscriptions.push(this.getUpcomingTime().subscribe(x => this.remainingTime = x));
    }
  }

  ngOnInit(): void {
    this.appointmentTime = new Date(this.appointment.schedule.scheduledStartTime);
  }

  private getUpcomingTime(): Observable<number> {
    return this.everySecond.pipe(map(() => {
      const currentTime = new Date();
      return Math.abs((this.appointmentTime.getTime() - currentTime.getTime())) / (1000 * 60);
    }));
  }

  public toggle() {
    this.showCollapseButton = !this.showCollapseButton;
  }

  public startAppointment() {
    this.router.navigate(
      ["/behavioral-health-visit/"],
      {
        relativeTo: this.route,
        queryParams: { id: this.appointment.sourceId },
      });
  }

  public cancel() {
    if (confirm("Are you sure you want to cancel this appointment?")) {
      this.sdk.cancelConsumerAppointments(this.appointment).then(cancelled => {
        if (cancelled) {
          this.router.navigate(["dashboard"]);
        }
      }).catch((exception) => {
        var consumer = this.sdk.getConsumer();
        this.logging.LogError('AppointmentCardComponent', 'error calling cancelConsumerAppointments', exception, consumer);
        this.snackbar.open('Oops, an error has occurred. We could not cancel your appointment. To cancel, please try again.', 'Retry')
          .onAction().subscribe(async () => {
            this.cancel();
          });
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
