import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceSetupProcessService } from './device-setup-process.service';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule
  ]
})
export class DeviceSetupServiceModule {

  constructor(@Optional() @SkipSelf() parentModule?: DeviceSetupServiceModule) {
    if (parentModule) {
      throw new Error(
        'Shared Service Module is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(deviceProcess: DeviceSetupProcessService): ModuleWithProviders<DeviceSetupServiceModule> {
    return {
      ngModule: DeviceSetupServiceModule,
      providers: [
        { provide: DeviceSetupProcessService, useValue: deviceProcess }
      ]
    };
  }
}
