
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
     names: {
         signUpSignIn: environment.msalPolicySignIn
     },
     authorities: {
         signUpSignIn: {
             authority: `https://${environment.msalTenantName}.b2clogin.com/${environment.msalTenantId}/${environment.msalPolicySignIn}`,
         }
     },
     authorityDomain: `${environment.msalTenantName}.b2clogin.com`
 };


export const msalConfig: Configuration = {
     auth: {
         clientId: environment.msalClientId,
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: location.origin
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE,
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                // TODO: can we use the logging service
               // console.log(message);
             },
             logLevel: LogLevel.Error,
             piiLoggingEnabled: false
         }
     }
 };

 export const protectedResources = {
  b2cMemberXrefApi: {
    endpoint: environment.urlBase + '/b2cmemberxref',
    scopes: [environment.msalScopes],
  },
};

export const loginRequest = {
  scopes: [environment.msalScopes]
};

export const PHONE_REGEX = /^\(((?!(0))\d)(?!\1{2}\))\d{2}\) \d{3}-\d{4}$/;

