<section>
<article>
  <h1>Scheduled Appointments</h1>
  <ng-container *ngIf="dependants && dependants.length > 1">
    <label *ngIf="dependants">Select Family Member</label>
    <form [formGroup]="appointmentForm">
      <select class="form-control" formControlName="dependent" (change)="onDependentChange()">
        <option *ngFor="let dependant of dependants" [selected]="dependants[0].id.persistentId" [ngValue]="dependant.id.persistentId">{{dependant.fullName}} (Age {{dependant.age}})</option>
      </select>
    </form>
  </ng-container>
  <content-loader *ngIf="!dependants" viewBox="0 0 600 150" preserveAspectRatio="YMax meet" foregroundColor="#D1D5DB">
    <svg:rect x="0" y="30" rx="3" ry="3" width="652" height="50" />
    <svg:rect x="0" y="5" rx="0" ry="0" width="143" height="19" />
  </content-loader>
  <ng-container *ngIf="(consumerAppointments && consumerAppointments.length !== 0); else noRecordFound">
    <div *ngFor="let appointment of consumerAppointments; let indexOfelement=index;">
      <app-appointment-card [appointment]="appointment" [upComingVisit]="indexOfelement === 0"></app-appointment-card>
    </div>
  </ng-container>
  <ng-template #noRecordFound>
    <p class="none-available" *ngIf="!loadingAppointments">There are no appointments scheduled for {{selectedConsumer?.firstName}}.</p>
  </ng-template>

  <content-loader *ngIf="loadingAppointments" viewBox="0 0 600 300" preserveAspectRatio="YMax meet" foregroundColor="#D1D5DB">
    <svg:rect x="0" y="7" rx="0" ry="0" width="143" height="24" />
    <svg:rect x="0" y="45" rx="0" ry="0" width="164" height="16" />
    <svg:rect x="0" y="75" rx="0" ry="0" width="90" height="16" />
    <svg:rect x="113" y="75" rx="0" ry="0" width="90" height="16" />
    <svg:rect x="0" y="106" rx="0" ry="0" width="140" height="50" />
    <svg:rect x="163" y="104" rx="0" ry="0" width="140" height="50" />
    <svg:rect x="0" y="176" rx="0" ry="0" width="640" height="50" />
    <svg:rect x="0" y="238" rx="0" ry="0" width="640" height="50" />
  </content-loader>

  <div class="action-items">
    <button (click)="Back()" title="Go Back" class="link-button back">Go Back</button>
  </div>
</article>
</section>
