import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (state.url) {
            localStorage.setItem('REDIRECT_URL', state.url);
        }

        if (!this.authService.isLoggedIn) {
            return this.router.createUrlTree(['']);
        }
        return true;
    }

}
