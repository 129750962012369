import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeContactMethodRequest } from '../models/change-contact-method-request';
import { CommunicationResult } from '../models/communication-result';
import { JwtHelperService } from '@auth0/angular-jwt';

const API_URL = environment.apimBase;

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private http: HttpClient,
    private storage: StorageService,
    private jwtHelper: JwtHelperService) { }

  public GetHomeEmail(): Observable<CommunicationResult> {
    let muk = this.jwtHelper.decodeToken(this.storage.accessToken)?.MemberUniqueKey;
    const url = API_URL + `/ihub-communication-web/v1/contact/member/${muk}/contact-type/HOMEEMAIL`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.storage.accessToken);
    return this.http.get<CommunicationResult>(url, { headers }).pipe(
      catchError(err => throwError(err))
    );
  }

  public GetCellPhone(): Observable<CommunicationResult> {
    let muk = this.jwtHelper.decodeToken(this.storage.accessToken)?.MemberUniqueKey;
    const url = API_URL + `/ihub-communication-web/v1/contact/member/${muk}/contact-type/CELLPHONE`;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.storage.accessToken);
    return this.http.get<CommunicationResult>(url, { headers }).pipe(
      catchError(err => throwError(err))
    );
  }


  public SetContactMethod(request: ChangeContactMethodRequest): Observable<any> {
    let muk = this.jwtHelper.decodeToken(this.storage.accessToken)?.MemberUniqueKey;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.storage.accessToken);
    return this.http.put(API_URL + '/ihub-communication-web/v1/contact/member/' + muk, JSON.stringify(request), { headers }).pipe(
      catchError(err => throwError(err))
    );
  }

  public CreateEmailRequest(email: string) {
    const emailRequest = new ChangeContactMethodRequest();
    emailRequest.ChangeUserId = 'MemberPortal';
    emailRequest.ContactTypeId = 'HOMEEMAIL';
    emailRequest.ContactTypeValue = email;
    return emailRequest;
  }

  public CreatePhoneRequest(phone: string) {
    const phoneRequest = new ChangeContactMethodRequest();
    phoneRequest.ChangeUserId = 'MemberPortal';
    phoneRequest.ContactTypeId = 'CELLPHONE';
    phoneRequest.ContactTypeValue = phone;
    return phoneRequest
  }
}
