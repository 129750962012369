import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'specialityMapPipe',
})
export class SpecialityMapPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        if (value) {
            if (value === "Urgent Care") {
                return '24/7 Sick Care';
            } else if (value === "Family Physician") {
                return 'Talk Therapy';
            } else {
                return value;
            }
        }
    }
}
