import { LoggingService } from 'src/app/logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import awsdk from '@bluekc/awsdk';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { LinklogicService } from '../../services/link-logic.service';
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit {

  VisitCost = 0;
  CostLoading = false;
  PaymentMethodLoading = false;
  PaymentEntered!: boolean;
  SelectedPaymentMethod: awsdk.AWSDKPaymentMethod | undefined;
  CardExpired!: boolean;
  ShowDeclinationError = false;
  public IsMaMember = false;
  CostFailed: boolean = false;

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public storageService: StorageService,
    public sdk: AmwellSDKService,
    @Inject('updateStep') private updateStep: string,
    private patient: awsdk.AWSDKConsumer | null,
    private linkLogicService: LinklogicService,
    public snackbar: MatSnackBar,
    public logging: LoggingService
  ) {
    super(process, router, location, sdk, logging);
    this.IsMaMember = this.linkLogicService.isMAMember();
  }

  async ngOnInit() {
    await this.loadContent();
  }

  public NextStep() {
    let currentProcess = this.process.Process;
    currentProcess.payment.Value = true;
    currentProcess.payment.DisplayValue = `$${this.VisitCost.toFixed(2)}`;
    currentProcess.payment.DisplayValueLine2 = `${this.SelectedPaymentMethod?.type} Card ending in ${this.SelectedPaymentMethod?.lastDigits}`;

    this.storageService.visitProcess = JSON.stringify(currentProcess);
    this.router.navigate(['waiting-room']);
  }

  public NewPaymentEntered(event: awsdk.AWSDKPaymentMethod | undefined) {
    this.logging.LogWithData("Payment", "New payment entered", {'paymentMethod':JSON.stringify(event)});
    if (event === undefined) {
      return;
    }

    this.SelectedPaymentMethod = event;
    this.ShowDeclinationError = false;
    this.PaymentEntered = true;
  }

  ConfirmCardChange(): void {
    if (confirm('Are you sure you want to remove this payment method?')) {
      this.PaymentEntered = false;
    }
  }

  public RegisterOopsie() {
    this.snackbar.open('An error has occurred. Your payment method has not been saved. To continue, please try again.');
  }

  goBack() {
    super.Back();
  }

  private async loadContent() {
    this.PaymentEntered = true;
    this.CostLoading = true;
    this.PaymentMethodLoading = true;

    //SDK logic
    let currentProcess = this.process.Process;
    if (this.patient === null)
      return;

    let visit = currentProcess[this.updateStep] as ProcessStep<awsdk.AWSDKVisit>;

    if (typeof currentProcess.payment.Value === "string") {
      if (currentProcess.payment.Value = awsdk.AWSDKErrorCode.creditCardDeclinedError) {
        this.ShowDeclinationError = true;
      } else {
        this.logging.LogGenericConsumerError("Payment", "Unexpected error from Payment Component", this.patient, JSON.stringify(currentProcess.payment.Value));
        return;
      }
    }

    if (visit.Validation()) {
      try {
        let updatedVisit = await this.sdk.waitForVisitCostCalc(visit.Value);
        if (updatedVisit) {
          this.VisitCost = updatedVisit.cost.zeroCostVisit ? 0 : updatedVisit.cost.expectedConsumerCopayCost;
          currentProcess[this.updateStep].Value = updatedVisit;
          this.CostLoading = false;
        }
        else {
          this.CostLoading = false;
          this.logging.LogWithData("Payment", "Visit is Null", "none provided");
        }
      } catch (exception) {
        this.CostFailed = true;
        this.logging.LogError("Payment", "error calling waitForVisitCostCalc", exception as awsdk.AWSDKError, this.patient);
        this.snackbar.open('An error has occurred. To see the visit cost before continuing, please try again.', 'Retry')
        .onAction().subscribe(async () => {
            await this.loadContent();
            this.CostFailed = false;
          });
      }

      try {
        this.SelectedPaymentMethod = await this.sdk.getPaymentMethod(this.patient);
      } catch (exception) {
        this.logging.LogError("Payment", "error calling getPaymentMethod",exception as awsdk.AWSDKError, this.patient);
      }
      this.PaymentEntered = this.SelectedPaymentMethod != null;
      this.PaymentMethodLoading = false;
    }
  }
}
