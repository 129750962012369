import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonePipe',
})
export class PhonePipe implements PipeTransform {
  // if the value passed in length == 10, then apply (999) 999-9999 format; else, return the value passed in
  transform(value: string, args?: any): any {
    if (value && value.length > 0) {
      let areaCode, number;

      switch (value.length) {
        case 10:
          areaCode = value.slice(0, 3);
          number = value.slice(3);
          break;
        default:
          return value.replace(/\D/g, '');
      }

      return `(${areaCode}) ${number.slice(0, 3)}-${number.slice(3)}`;
    } else {
      return value;
    }
  }
}
