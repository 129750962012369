import { StorageService } from "./storage.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { BkcToken } from "../models/bkcToken";

@Injectable({
  providedIn: 'root'
})
export class VcJwtManagerService {

  constructor(private jwtHelperService: JwtHelperService, private storageService: StorageService) { }

  public get MemberCK(): number {
    const jwt = this.jwtHelperService.decodeToken(this.storageService.accessToken) as BkcToken;
    if (jwt) {
      if (jwt.MemberUniqueKey) {
        return parseInt(jwt.MemberUniqueKey, 10);
      }
      if (jwt.extension_MemeCK) {
        return parseInt(jwt.extension_MemeCK, 10);
      }
    }

    return 0;
  }

  public get BeKey(): number {
    const jwt = this.jwtHelperService.decodeToken(this.storageService.accessToken) as BkcToken;
    if (jwt) {
      return parseInt(jwt.extension_BEKey, 10);
    }
    return 0;
  }

  public GetEmail(accessToken: string): string {
    const jwt = this.jwtHelperService.decodeToken(accessToken);
    if (jwt.email) {
      return jwt.email;
    }
    return '';
  }
}
