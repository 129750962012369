import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import awsdk from '@bluekc/awsdk';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BlueKcValidators } from '@bluekc/bkc-validators';
import { AmwellSDKService } from '../../services/amwell-sdkservice.service';
import { LoggingService } from 'src/app/logging/logging.service';

@Component({
  selector: 'app-addres-confirm',
  templateUrl: './addres-confirm.component.html',
  styleUrls: ['./addres-confirm.component.css']
})
export class AddresConfirmComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
  implements OnInit {

  ShowAddress1Error: Boolean = false;
  ShowCityError: Boolean = false;
  ShowZipError: Boolean = false;
  ShowStateError: Boolean = false;
  ShowInvalidZipError: Boolean = false;
  States: awsdk.AWSDKState[] | undefined = [];
  AddressFormGroup: FormGroup;
  Address: awsdk.AWSDKAddress | null | undefined;
  AddressEntered: Boolean = false;
  Loading: boolean = false;

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    @Inject('updateStep') private updateStep: string,
    public patient: awsdk.AWSDKConsumer | null,
    public snackbar: MatSnackBar,
    public logging: LoggingService
  ) {
    super(process, router, location, sdk, logging);

    this.AddressFormGroup = new FormGroup({
      'Address1': new FormControl(this.Address?.address1, [Validators.required]),
      'Address2': new FormControl(this.Address?.address2),
      'City': new FormControl(this.Address?.city, [Validators.required]),
      'StateControl': new FormControl(this.Address?.geographicalState, [Validators.required, BlueKcValidators.state]),
      'ZipCodeControl': new FormControl(this.Address?.zipCode, [Validators.required, BlueKcValidators.zipCode])
    });
  }

  async ngOnInit() {
    this.Loading = true;
    try {
      this.States = await this.sdk.getStates();
      //Get Patient address info to prefill the form
      if (this.patient == null) {
        this.Loading = false;
        return;
      }
      this.Address = await this.sdk.getShippingAddress(this.patient!);
      if (!this.Address)
        this.Address = this.patient.address;
      if (!this.Address) {
        this.Loading = false;
        return;
      };
      this.AddressFormGroup.get('Address1')?.setValue(this.Address?.address1);
      this.AddressFormGroup.get('Address2')?.setValue(this.Address?.address2);
      this.AddressFormGroup.get('City')?.setValue(this.Address?.city);

      this.AddressFormGroup.get('StateControl')?.setValue(this.Address?.stateCode);
      this.AddressFormGroup.get('ZipCodeControl')?.setValue(this.Address?.zipCode);

      this.AddressEntered = true;
    }
    catch (error) {
      //TODO: general error handling, we will just move on, but maybe we should log?
    }
    this.Loading = false;
  }

  NextStep(address: awsdk.AWSDKAddress): void {
    let currentProcess = this.process.Process;
    currentProcess[this.updateStep].Value = address;
    super.SaveAndGo(currentProcess);
  }

  async EnterAddress() {
    try {
      var address1 = this.AddressFormGroup.get('Address1')?.value;
      var address2 = this.AddressFormGroup.get('Address2')?.value;
      var city = this.AddressFormGroup.get('City')?.value;
      var state = this.States?.find(a => a.code === this.AddressFormGroup.get('StateControl')?.value);
      var zip = this.AddressFormGroup.get('ZipCodeControl')?.value;
      let addressUpdate = await this.sdk.getShippingAddressUpdate(address1, address2, city, state!, zip);
      this.Address = await this.sdk.updateShippingAddress(this.patient!, addressUpdate);
      this.AddressEntered = !this.AddressEntered;
    }
    catch (error) {
      this.snackbar.open('Oops, an error has occurred. To save this information and continue, please try again.');
    }
  }

  ValidateForm() {
    //refresh defaults
    this.ShowAddress1Error = false;
    this.ShowCityError = false;
    this.ShowStateError = false;
    this.ShowZipError = false;
    this.ShowInvalidZipError = false;

    if (this.AddressFormGroup.get('Address1')?.dirty && this.AddressFormGroup.get('Address1')?.invalid) {
      this.ShowAddress1Error = true;
    }
    if (this.AddressFormGroup.get('City')?.dirty && this.AddressFormGroup.get('City')?.invalid) {
      this.ShowCityError = true;
    }
    if (this.AddressFormGroup.get('StateControl')?.dirty && this.AddressFormGroup.get('StateControl')?.invalid) {
      this.ShowStateError = true;
    }
    if (this.AddressFormGroup.get('ZipCodeControl')?.dirty && this.AddressFormGroup.get('ZipCodeControl')?.hasError('required')) {
      this.ShowZipError = true;
    }
    if (this.AddressFormGroup.get('ZipCodeControl')?.dirty && this.AddressFormGroup.get('ZipCodeControl')?.hasError('zipCode')) {
      this.ShowInvalidZipError = true;
    }
  }
}
