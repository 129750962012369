import { LoggingService } from 'src/app/logging/logging.service';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { PhonePipe } from './../../pipes/phone.pipe';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import awsdk from '@bluekc/awsdk';

@Component({
  selector: 'app-callback-number',
  templateUrl: './callback-number.component.html',
  styleUrls: ['./callback-number.component.css']
})
export class CallbackNumberComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit {
  private PhonePipe = new PhonePipe();

  public CallbackNo = '8165551234';

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    private patient: awsdk.AWSDKConsumer | null,
    public showBehavioralHealthText: Boolean = false,
    public sdk: AmwellSDKService,
    public logging: LoggingService
  ) {
    super(process, router, location, sdk, logging);
  }

  ngOnInit() {

    if (this.patient === null)
      return;
    //check for existing number saved in flow
    let currentProcess = this.process.Process;
    if (currentProcess['callback-number'].Validation()) {
      this.CallbackNo = currentProcess['callback-number'].Value as string;
    } else {
      this.CallbackNo = this.patient.phone;
    }
    this.CallbackNo = this.PhonePipe.transform(this.CallbackNo);
  }

  public NextStep() {
    let currentProcess = this.process.Process;
    currentProcess['callback-number'].Value =  this.PhonePipe.transform(this.CallbackNo);
    currentProcess['callback-number'].DisplayValue = this.CallbackNo;
    super.SaveAndGo(currentProcess);
  }

  public AltNumber() {
    this.router.navigate([`/${this.process.ProcessRouteBase}/callback-number/callback-other`]);
  }
}
