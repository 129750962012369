import { MsalGuard } from '@azure/msal-angular';
import { NoProvidersAvailableComponent } from './shared/components/no-providers-available/no-providers-available.component';
import { ErrorComponent } from './error/error.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BehavioralHealthAppointmentsComponent } from './behavioral-health-appointments/behavioral-health-appointments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OnlyLoggedInUsersGuard } from './shared/guards/login.guard';
import { OnlyLoggedOutUsersGuard } from './shared/guards/logout.guard';

const routes: Routes = [
  {
    path: '', children: [
      {
        path: '',
        canActivate: [OnlyLoggedOutUsersGuard],
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'urgent-care-visit',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./urgent-care-visit/urgent-care-visit.module').then(m => m.UrgentCareVisitModule),
        data: {
          title: 'Start a 24/7 Sick Care Visit – Blue KC Virtual Care'
        }
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [OnlyLoggedInUsersGuard],
        data: {
          title: 'Dashboard – Blue KC Virtual Care'
        }
      },
      {
        path: 'preferences',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule),
        data: {
          title: 'Preferences – Blue KC Virtual Care'
        }
      },
      {
        path: 'scheduled-appointments',
        component: BehavioralHealthAppointmentsComponent,
        canActivate: [OnlyLoggedInUsersGuard]
      },
      {
        path: 'behavioral-health-scheduling',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./behavioral-health-scheduling/behavioral-health-scheduling.module').then(m => m.BehavioralHealthSchedulingModule),
        data: {
          title: 'Schedule a Behavioral Health Visit – Blue KC Virtual Care'
        }
      },
      {
        path: 'previous-visits',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./previous-visits/previous-visits.module').then(m => m.PreviousVisitsModule),
        data: {
          title: 'Previous Visits – Blue KC Virtual Care'
        }
      },
      {
        path: 'waiting-room',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./waiting-room/waiting-room.module').then(m => m.WaitingRoomModule),
        data: {
          title: 'Waiting Room'
        }
      },
      {
        path: 'behavioral-health-visit',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./behavioral-health-visit/behavioral-health-visit.module').then(m => m.BehavioralHealthVisitModule)
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        canActivate: [OnlyLoggedInUsersGuard],
        data: {
          title: 'Contact Us – Blue KC Virtual Care'
        }
      },
      {
        path: 'no-providers-available',
        component: NoProvidersAvailableComponent,
        canActivate: [OnlyLoggedInUsersGuard]
      }
    ]
  },
  {
    path: 'messages',
    canActivate: [OnlyLoggedInUsersGuard],
    loadChildren: () => import('./message-center/message-center.module').then(m => m.MessageCenterModule),
    data: {
      title: 'Message Center – Blue KC Virtual Care'
    }
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
