import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SSO } from '../../models/sso/sso';
import { SSOTypes } from '../../models/sso/sso-types';
import { SsoService } from '../../services/sso.service';

@Component({
  selector: 'app-sso-modal',
  templateUrl: './sso-modal.component.html',
  styleUrls: ['./sso-modal.component.css']
})
export class SsoModalComponent implements OnInit {

  public loading = false;
  ssoLoadingHtml = `
  <html>
      <head>
      <style type="text/css">
      .shell{
          position:relative;
          text-align:center;
          padding:20px 40px 40px 40px;
          border-radius:0;
          color:#393E40;
          font-family:sans-serif;
      }
      .shell p { margin:20px 0; font-size:14px; }

      .three{
        background-color: #eee;
        padding: 50px 0;
      }

      /* ALL LOADERS */

      .loader{
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: relative;
        margin: 0 auto;
      }

      /* LOADER 1 */

      #loader-1:before, #loader-1:after{
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 10px solid transparent;
        border-top-color: #3498db;
      }

      #loader-1:before{
        z-index: 100;
        animation: spin 1s infinite;
      }

      #loader-1:after{
        border: 10px solid #ccc;
      }

      @keyframes spin{
        0%{
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100%{
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
          </style>
          <body>
          <div class="shell">
        <img [attr.test-id]="'ssoImage'" src="${this.data.partnerLogo}"><img>
        <p>${this.data.description}</p>
      <div class="loader" id="loader-1"></div>
      </div>
      </body>
      </html>
      `;

  constructor(private dialogRef: MatDialogRef<SsoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SSO,
    private ssoService: SsoService) { }

  ngOnInit() {
    if (this.data.partnerLogo === '') {
      this.sso();
    }
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  redirect() {
  }

  sso() {
    switch (this.data.ssoType) {
      case SSOTypes.Custom:
        if (this.data.ssoValue === 'trvalidation') {
          this.thomsonReuters(this.data.ssoValue);
        }
        break;
      case SSOTypes.URL:
        this.urlRedirect(this.data.ssoValue);
        break;
      case SSOTypes.SSO:
        this.ssoStandard(this.data.ssoValue);
        break;
      default:
        break;
    }
  }

  ssoStandard(method: string) {
    this.loading = true;
    const w = window.open('', '_blank')!;
    w.document.write(this.ssoLoadingHtml);
    this.onNoClick();
    this.ssoService.SsoStandard(method).subscribe(res => {
      const html = res as string;
      w.document.open();
      w.document.write(html);
      w.document.close();
      this.loading = false;
    },
      () => {
        this.loading = false;
      });
  }

  thomsonReuters(method: string) {
    // no loader and window opens immediately
    const w = window.open('', '_blank')!;
    w.document.write(this.ssoLoadingHtml);
    this.onNoClick();
    this.ssoService.SsoStandard(method).subscribe(res => {
      const html = res as string;
      w.document.open();
      w.document.write(html);
      w.document.close();
    },
      () => {
        this.loading = false;
      });
  }

  urlRedirect(url: string) {
    window.open(url, '_blank');
    this.dialogRef.close();
  }

}
