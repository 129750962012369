import awsdk from '@bluekc/awsdk';
import { Injectable } from '@angular/core';
import { AppInsightsService } from '../shared/services/app-insights.service';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(
    private appInsightsService: AppInsightsService){}
  

  public LogWithData(componentName: string, message: string, data: any, level: string = 'INFO', errorLevel: string = 'Debug') {
    this.appInsightsService.logEvent('Virtual Care Event', {component: componentName, data: data, level: level, errorLevel:errorLevel});
  }

  public Log(componentName: string, message: string) {
    this.LogWithData(componentName, message, { data: 'none provided' });
  }

  public LogGenericError(componentName: string, message: string, data = {}){
    this.LogWithData(componentName, message, data, 'WARN', 'Critical');
  }

  public LogGenericConsumerError(componentName: string, message: string, consumer: awsdk.AWSDKConsumer, exceptionData?: string) {
    this.LogWithData(componentName, message, {
      'fullName': JSON.stringify(consumer?.fullName),
      'email': JSON.stringify(consumer?.email),
      'subscriberID': JSON.stringify(consumer?.subscription?.subscriberId),
      'sourceID': JSON.stringify(consumer?.sourceId),
      'amwellPersistantID': JSON.stringify(consumer?.id?.persistentId),
      'timezone': JSON.stringify(consumer?.timeZone),
      'exceptionData': exceptionData
    }, 'WARN', 'Critical');
  }

  public LogError(componentName: string, message: string, error: awsdk.AWSDKError, consumer: awsdk.AWSDKConsumer) {
    var data = {
        'fullName': JSON.stringify(consumer?.fullName),
        'email': JSON.stringify(consumer?.email),
        'subscriberID': JSON.stringify(consumer?.subscription?.subscriberId),
        'sourceID': JSON.stringify(consumer?.sourceId),
        'amwellPersistantID': JSON.stringify(consumer?.id?.persistentId),
        'timezone': JSON.stringify(consumer?.timeZone),
        'exceptionCode': JSON.stringify(error.errorCode),
        'exceptionHttpResponseCode': JSON.stringify(error.httpResponseCode),
        'exceptionFieldName': JSON.stringify(error.fieldName),
        'exceptionMessage': JSON.stringify(error.message),
        'exceptionReason': JSON.stringify(error.reason),
        'exceptionErrors': JSON.stringify(error.errors),
        'exceptionStackTrace': JSON.stringify(error.stackTrace),
        'exceptionRecoverySuggestion': JSON.stringify(error.recoverySuggestion)
    };
    this.LogWithData(componentName, message, data, 'WARN', 'Critical');
  }

  public LogUserAction(componentName: string, message: string, consumer: awsdk.AWSDKConsumer, additionalData?: string) {
    this.LogWithData(componentName, message, {
        'fullName': JSON.stringify(consumer?.fullName),
        'email': JSON.stringify(consumer?.email),
        'subscriberID': JSON.stringify(consumer?.subscription?.subscriberId),
        'sourceID': JSON.stringify(consumer?.sourceId),
        'amwellPersistantID': JSON.stringify(consumer?.id?.persistentId),
        'timezone': JSON.stringify(consumer?.timeZone),
        'additionalData': additionalData
    });
  }

}
