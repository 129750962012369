import { StorageService } from 'src/app/shared/services/storage.service';
import { OnlyLoggedOutUsersGuard } from './shared/guards/logout.guard';
import { OnlyLoggedInUsersGuard } from './shared/guards/login.guard';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JwtModule } from '@auth0/angular-jwt';
import { BLACKLISTEDROUTES } from './blacklisted.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { DeviceSetupProcessService } from './processes/device-setup-process.service';
import { DeviceSetupServiceModule } from './processes/device-setup-service-module';
//import { SsoComponent } from './sso/sso.component';
import { VcJwtManagerService } from './shared/services/vc-jwt-manager.service';
import { SessionValues } from './shared/services/session-values.model';
import { AmwellSDKService } from './shared/services/amwell-sdkservice.service';
import { BehavioralHealthAppointmentsComponent } from './behavioral-health-appointments/behavioral-health-appointments.component';
import { AppointmentCardComponent } from './behavioral-health-appointments/appointment-card/appointment-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './error/error.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

// MSAL
// Import MSAL and MSAL browser libraries.
import { MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalService } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

// Import the Azure AD B2C configuration
import { msalConfig, protectedResources } from './auth-config';
import { AppInsightsService } from './shared/services/app-insights.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';


export function jwtHelperFactory() {
    /* This intercepts ALL Http calls and replaces the auth header with this value
    * TODO: Currently we are saving the last mile jwt as the access_token to call to member
    * As we complete the apigee to APIM migration and all our services switch to using the b2c token
    * this will change.
     */
  return sessionStorage.getItem(SessionValues.ACCESS_TOKEN);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [protectedResources.b2cMemberXrefApi.endpoint, protectedResources.b2cMemberXrefApi.scopes]
    ])
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid',environment.msalScopes]
    }
  };
}

export function initializeAppFactory(sdk: AmwellSDKService): () => Promise<any> {

  let configuration = {
    sdkApiKey: environment.sdkApiKey,
    baseUrl: environment.baseUrl,
    locale: environment.locale,
    isIVRCallbackEnabled: environment.isIVRCallbackEnabled,
  }

  return async () => {
    await sdk.initialize(configuration);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    BehavioralHealthAppointmentsComponent,
    AppointmentCardComponent,
    ErrorComponent,
    ContactUsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DeviceSetupServiceModule.forRoot(new DeviceSetupProcessService(new StorageService())),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtHelperFactory,
        allowedDomains: environment.jwtWhitelistUrl.split(','),
        disallowedRoutes: BLACKLISTEDROUTES,
      },
    }),
    SharedModule,
    MsalModule
  ],
  providers: [
    OnlyLoggedInUsersGuard,
    OnlyLoggedOutUsersGuard,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AmwellSDKService],
      useFactory: initializeAppFactory
    },
    VcJwtManagerService,
    StorageService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 8000, panelClass: 'snackbar' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalService,
    AppInsightsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [BrowserAnimationsModule]
})
export class AppModule { }
