import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';
import { DeviceSetupProcessStepNames } from './device-setup-process-step-names';
import { DeviceSetupProcessInterface } from './device-setup-process.interface';

@Injectable({ providedIn: 'root' })
export class DeviceSetupProcessService {

  public ParentRoute = '';

  private initialState: DeviceSetupProcessInterface = {
    [DeviceSetupProcessStepNames.ENABLE_CAMERA]: {
      Value: false,
      Validation() { return this.Value }
    },
    [DeviceSetupProcessStepNames.TEST_CAMERA]: {
      Value: false,
      Validation() { return this.Value }
    },

    [DeviceSetupProcessStepNames.ENABLE_MIC]: {
      Value: false,
      Validation() { return this.Value }
    },
    [DeviceSetupProcessStepNames.TEST_MIC]: {
      Value: false,
      Validation() { return this.Value }
    },

    [DeviceSetupProcessStepNames.TEST_AUDIO]: {
      Value: false,
      Validation() { return this.Value }
    },
    [DeviceSetupProcessStepNames.FINISHED]: {
      Value: false,
      Validation() { return false; }
    }
  };

  public CurrentState = new BehaviorSubject<DeviceSetupProcessInterface>(this.initialState);

  constructor(public storage: StorageService) {
    //host process should make sure to reset device settings if needed
  }

  private CheckCurrentDeviceSettings() {
    try {

      if (navigator.permissions && navigator.permissions.query) {
        const permissionCam = "camera" as PermissionName;
        const permissionMic = "microphone" as PermissionName;

        navigator.permissions.query({ name: permissionCam  }).then(
          (currentCameraPerms) => {

            if (currentCameraPerms.state === 'granted') {
              let currentProcess = this.CurrentState.value;
              currentProcess['enable-camera'].Value = true;
              this.Process = currentProcess;
            }
        });

        navigator.permissions.query({ name: permissionMic }).then(
          (currentMicPerms) => {
            if (currentMicPerms.state === 'granted') {
              let currentProcess = this.CurrentState.value;
              currentProcess['enable-mic'].Value = true;
              this.Process = currentProcess;
            }
        });
      }
    } catch(e) {
      //SAFARI, they always have to prompt so no point in checking
    }
  }

  public set Process(value: DeviceSetupProcessInterface) {
      this.CurrentState.next(value);
  }

  public get Process(): DeviceSetupProcessInterface {
    return this.CurrentState.value;
  }

  public get FirstInvalidStep(): string {
    var first = Object.keys(this.CurrentState.getValue())
      .find(propertyName => this.CurrentState.getValue()[propertyName].Validation() === false) ?? '';

    return first;
  }

  public IsStepValid(step: string): boolean {
    if (!this.CurrentState.value[step])
      return false;

    return this.CurrentState.value[step].Validation();
  }

  public resetSteps(firstStep: string = ""): void {
    let firstStepFound = false;
    let currentState = this.Process;

    Object.keys(currentState)
      .forEach(propertyName => {
        if (firstStep === "" || propertyName === firstStep || firstStepFound) {
          firstStepFound = true;
          currentState[propertyName].Value = false;
          currentState[propertyName].DisplayValue = undefined;
          currentState[propertyName].DisplayValueLine2 = undefined;
        }
      });
    this.Process = currentState;
  }
}
