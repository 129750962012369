import { StorageService } from './../../services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import awsdk from '@bluekc/awsdk';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { VcJwtManagerService } from '../../services/vc-jwt-manager.service';
import { MemberService } from '../../services/member.service';
import { Member } from "../../models/member";
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { LoggingService } from 'src/app/logging/logging.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
})
export class PatientComponent
  <T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>
  implements OnInit, OnDestroy {

  Consumer!: awsdk.AWSDKConsumer;
  Dependants: awsdk.AWSDKConsumer[] = [];
  ActiveConsumer!: awsdk.AWSDKConsumer;
  FamilyMembers: Member[] = [];
  subscription: Subscription | undefined;
  Loading: boolean = false;
  NeedDependent: boolean = true;
  RegisteredDependents: awsdk.AWSDKConsumer[] = [];
  singleMember = false;

  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public vcJwtHelperService: VcJwtManagerService,
    public memberApi: MemberService,
    public snackBar: MatSnackBar,
    public storageService: StorageService,
    public logging: LoggingService
  ) {
    super(process, router, location, sdk, logging);
  }

  ngOnInit() {
    this.LoadPage();
  }

  private async LoadPage() {
    this.Loading = true;
    this.NeedDependent = true;
    this.Consumer = this.sdk.getConsumer();
    if (this.Consumer) {
      this.sdk.getDependents().then((dependants) => {

        // for (const d of dependants) {
        //   let existsDependent = this.Dependants.find(m => m.firstName === d.firstName && m.age === d.age);
        //   if(!existsDependent){
        //     this.Dependants.push(d);
        //   }
        // }

        //this.Dependants = dependants.filter(a => a.firstName !== this.Consumer.firstName).sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.Dependants = dependants.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.Dependants.unshift(this.Consumer);

        this.Loading = false;
      }).catch(exception => {
        this.logging.LogError("Patient", "error calling getDependents", exception, this.Consumer);
        this.snackBar.open('Oops, an error has occurred. To display the dependents on your policy, please try again.', 'Retry')
          .onAction().subscribe(async () => {
            this.LoadPage();
          });
      });
    }
  }

  private get MemberUniqueKey(): number {
    return this.vcJwtHelperService.MemberCK;
  }

  getFamilyMembers() {
    this.Loading = true;
    this.subscription = this.memberApi.ApiGetFamilyMembers(this.MemberUniqueKey).subscribe(
      async (res: Member[]) => {
        if (res) {
          this.FamilyMembers = res;
          // await this.FamilyMember.forEach(async (a) => {
          for (const a of this.FamilyMembers) {
            let existsDependent = this.Dependants.find(m => m.firstName === a.firstName && m.age === this.getAge(a.birthDate));
            if (!existsDependent) {
              let age = this.ageFromBirthDate(a.birthDate);
              if (age < 18) {
                let dependentRegistration = await this.sdk.newDependentRegistration();
                this.populateDependentPayload(dependentRegistration, a);
                let registeredDependent = await this.sdk.registerDependent(this.Consumer, dependentRegistration);
                this.Dependants.push(registeredDependent);
                this.RegisteredDependents.push(registeredDependent);
              }
            }
          }
          this.storageService.dependents = JSON.stringify(this.Dependants);
        }

        this.NeedDependent = this.RegisteredDependents === null || this.RegisteredDependents.length === 0 ? true : false;
        this.Loading = false;
      },
      (error) => {
        this.logging.LogError('Patient', 'Member Service failed to fetch family.', error, this.Consumer);
        this.Loading = false;
      });
  }

  getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age?.toString();
  }

  populateDependentPayload(payload: awsdk.AWSDKDependentRegistration, family: Member) {
    payload.firstName = family.firstName;
    payload.lastName = family.lastName;
    if (family.birthDate !== '') {
      payload.dob = new Date(family.birthDate);
    }
    payload.gender = family.gender;
    const middleNameOrInitial = family.middleInitial ? family.middleInitial : '';
    payload.middleName = middleNameOrInitial;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ageFromBirthDate(dateOfBirth: any): number {
    return moment().diff(dateOfBirth, 'years');
  }

  public NextStep(selectedConsumer: awsdk.AWSDKConsumer) {
    let currentProcess = this.process.Process;
    currentProcess.patient.Value = selectedConsumer;
    currentProcess.patient.DisplayValue = selectedConsumer.fullName;
    let consumerAge = this.ageFromBirthDate(selectedConsumer.dob);
    currentProcess['vitals'].Value = consumerAge > 18;
    if (consumerAge <= 18) {
      currentProcess['vitals'].Active = true;
      currentProcess['vitals'].DisplayName = 'Height & Weight';
    } else {
      currentProcess['vitals'].Active = false;
      currentProcess['vitals'].DisplayName = undefined;
    }
    super.SaveAndGo(currentProcess);
  }
}
