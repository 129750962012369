import { LoggingService } from 'src/app/logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { AmwellSDKService } from '../../services/amwell-sdkservice.service';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import awsdk from '@bluekc/awsdk';

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.css']
})
export class VitalsComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit {

  inputFeetHeight: number = 0;
  inputInchHeight: number = 0;
  inputWeightMajor: number = 0;
  Vitals: awsdk.AWSDKVitals = <awsdk.AWSDKVitals>{};
  vitalsForm: FormGroup;
  heightFeet: Array<any>;
  heightInches: Array<any>;
  heightFeetError: boolean = false;
  heightInchesError: boolean = false;
  weightError: boolean = false;
  formSubmitted: boolean = false;
  vitalSecondStep = 0;

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdkService: AmwellSDKService,
    public snackBar: MatSnackBar,
    public patient: awsdk.AWSDKConsumer,
    public logging: LoggingService) {
    super(process, router, location, sdkService, logging);
    this.heightFeet = [
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 }
    ];
    this.heightInches = [
      { value: 0 },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 },
      { value: 11 }
    ];
    this.vitalsForm = new FormGroup({
      inputFeet: new FormControl(this.inputFeetHeight, [Validators.required, Validators.min(0)]),
      inputInch: new FormControl(this.inputInchHeight, [Validators.required, Validators.min(0)]),
      inputWeight: new FormControl(this.inputWeightMajor, [Validators.required, Validators.min(1)])
    });
  }

  ngOnInit(): void {
    if (this.patient) {
      this.sdkService.getVitals(this.patient).then(
        response => {
          if (response) {
            this.Vitals = response;
            this.inputFeetHeight = this.Vitals.heightMajor ? this.Vitals.heightMajor : 0;
            this.inputInchHeight = this.Vitals.heightMinor ? this.Vitals.heightMinor : 0;
            this.inputWeightMajor = this.Vitals.weightMajor ? this.Vitals.weightMajor : 0;
            this.vitalsForm.get('inputFeet')?.setValue(this.inputFeetHeight);
            this.vitalsForm.get('inputInch')?.setValue(this.inputInchHeight);
            this.vitalsForm.get('inputWeight')?.setValue(this.inputWeightMajor);
          }
        }).catch(() => {
          //console.log('Failed to fetch patient vitals: ' + error);
          //TODO: add this error to logging?
          //In this scenario we are just catching the error and letting the user continue.
        });
    }
  }

  public async NextStep() {
    if (this.vitalsForm.valid) {
      let currentProcess = this.process.Process;
      if (this.patient) {
        this.formSubmitted = true;
        this.heightFeetError = false;
        this.heightInchesError = false;
        this.weightError = false;
        try {
          let reqVitals: awsdk.AWSDKVitals = this.Vitals;
          reqVitals.weightMajor = Number(this.vitalsForm.get('inputWeight')?.value);
          reqVitals.heightMajor = this.vitalsForm.get('inputFeet')?.value;
          reqVitals.heightMinor = this.vitalsForm.get('inputInch')?.value;
          let updatedVitals = await this.sdkService.updateVitals(this.patient, reqVitals);
          if (updatedVitals) {
            this.formSubmitted = false;
            currentProcess['vitals'].DisplayValue = this.vitalsForm.get('inputFeet')?.value + '\'' + this.vitalsForm.get('inputInch')?.value + "," + this.vitalsForm.get('inputWeight')?.value + ', lbs';
            currentProcess['vitals'].Value = true;
            super.SaveAndGo(currentProcess);
          }
        }
        catch (error) {
         this.snackBar.open('Oops, an error has occurred. To save this information and continue, please try again.');
        }
      }
    }
    else {
      this.displayError();
    }
  }

  displayError() {
    this.heightFeetError = false;
    this.heightInchesError = false;
    this.weightError = false;
    this.formSubmitted = true;
    if (this.vitalsForm.get('inputWeight')?.errors || this.vitalsForm.get('inputWeight')?.invalid && (this.vitalsForm.get('inputWeight')?.dirty || this.vitalsForm.get('inputWeight')?.touched)) {
      this.weightError = true;
    }
    if (this.vitalsForm.get('inputFeet')?.errors || this.vitalsForm.get('inputFeet')?.invalid && (this.vitalsForm.get('inputFeet')?.dirty || this.vitalsForm.get('inputFeet')?.touched)) {
      this.heightFeetError = true;
    }
    if (this.vitalsForm.get('inputInch')?.errors || this.vitalsForm.get('inputInch')?.invalid && (this.vitalsForm.get('inputInch')?.dirty || this.vitalsForm.get('inputInch')?.touched)) {
      this.heightInchesError = true;
    }
  }
}
