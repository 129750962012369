import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PlanDetail } from '../models/plandetail';
import { Encoder } from './encoder';

const API_URL = environment.apimBase;

@Injectable({
  providedIn: 'root'
})
export class PlanDetailService {

  constructor(private http: HttpClient) { }

  public ApiGetPlanDetails (memberId: number, categoryCode: string, yearIndicator: string): Observable<PlanDetail> {
    const headers = {
      params: new HttpParams({encoder: new Encoder()})
        .set('MemberUniqueKey', memberId.toString())
        .set('CategoryCode', categoryCode)
        .set('YearIndicator', yearIndicator)
    };

    const emptyPlanDetail: PlanDetail = {
      productId: '',
      productName: '',
      planName: '',
      networkName: '',
      planType: '',
      selected: false,
      category: '',
      benefitSummaries: []};

    return this.http.get<PlanDetail>(`${API_URL}/iHubServicesPlanDetails/v1/plandetails`, headers)
    .pipe( catchError ( () => of( emptyPlanDetail ) ));
  }
}
