<div *ngIf="MemberHasMedAllergies; then selectBlock else hasBlock"></div>
<ng-template #hasBlock>
        <h1>Are you allergic to any medications?</h1>
        <button (click)="ShowSelect()" class="secondary-button" title="Yes">Yes</button>
        <button (click)="NextStep()" class="secondary-button" title="No">No</button>
        <div class="action-items">
               <button (click)="Back()" class="link-button back" title="Go Back">Go Back</button>
        </div>
</ng-template>

<ng-template #selectBlock>
        <h1>Please note your medical allergies.</h1>
        <div [hidden]="!loading">Loading...</div>
        <div class="allergy-container">
                <div *ngFor="let med of MedAllergies" [hidden]="loading" class="allergies">
                        <label><input type="checkbox" [value]="med" [checked]="med.isCurrent" (change)="onNativeChange(med, $event)"> {{med.name}}</label>
                </div>
        </div>
        <div class="action-items">
                <button (click)="NextStep()" class="primary-button" title="Continue">Continue</button>
                <button (click)="Back()" class="link-button back" title="Go Back">Go Back</button>
        </div>
</ng-template>