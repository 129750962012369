import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availabilityDate'
})
export class AvailabilityDatePipe implements PipeTransform {

  transform(value: Date): string {
    const datepipe: DatePipe = new DatePipe('en-US');
    return `Available ${datepipe.transform(value, 'MMM d, y')!}  at ${datepipe.transform(value, 'h:mm a')!}`;
  }

}
