import { Component, Input } from '@angular/core';
import awsdk from '@bluekc/awsdk';

@Component({
  selector: 'app-payment-current',
  templateUrl: './payment-current.component.html',
  styleUrls: ['./payment-current.component.css']
})
export class PaymentCurrentComponent {

  @Input()
  PaymentMethod!: awsdk.AWSDKPaymentMethod | undefined;

}
