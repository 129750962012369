import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { ProcessStep } from "src/app/processes/ProcessStep.interface";
import { TimedArray } from "../models/timed-array";
import { AmwellSDKService } from "./amwell-sdkservice.service";
import { StorageService } from "./storage.service";

@Injectable()
export abstract class ProcessServiceInterface<T extends Record<string, ProcessStep<unknown>>>  {
    abstract LastStep: string;
    abstract readonly ProcessRouteBase: string;
    abstract DeclinedProviders: TimedArray;

    getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key];

    abstract _currentState: BehaviorSubject<T>;

    abstract _currentStepByRoute: BehaviorSubject<string>;

    constructor(public router: Router, public storage: StorageService, public sdk: AmwellSDKService) { }

    public set Process(value: T) {
        this._currentState.next(value);
    }

    public get Process(): T {
        return this._currentState.value;
    }

    public GetNextStep(currentStepName: string): string {
      let filteredSteps = this.GetActiveSteps();
      let c = filteredSteps.indexOf(currentStepName);

      if(c === -1) {
        //Get parent and return next step from parent
        let routeArray = this.router.url.split('/');
        let parent = routeArray[routeArray.length - 2];
        return this.GetNextStep(parent);
      }

      c++;
      return filteredSteps[c];
    }

    public GetActiveSteps(): Array<string> {
      let allTheValues = this._currentState.getValue();

      let filteredSteps = Object.keys(allTheValues).filter(key => {
        let step = allTheValues[key];
        if (step.Active !== false)
          return step;
        return;
      });

      return filteredSteps;
    }

  public get FirstInvalidStep(): string {
      return Object.keys(this.Process)
          .find(propertyName => {
            return this.Process[propertyName].Active !== false && !this.Process[propertyName].Validation();
          }) ?? '';
    }

    public IsStepValid(step: string): boolean {
      if (!this._currentState.value[step])
          return false;

      return this._currentState.value[step].Validation();
    }

    public resetSteps(firstStep: string = ""): void {
      let firstStepFound = false;
      let currentState = this._currentState.getValue();

      Object.keys(currentState)
        .forEach(propertyName => {
          if (firstStep === "" || propertyName === firstStep || firstStepFound) {
            firstStepFound = true;
            currentState[propertyName].Value = typeof (this._currentState.getValue()[propertyName].Value) === "boolean" ? false : null;
            currentState[propertyName].DisplayValue = undefined;
            currentState[propertyName].DisplayValueLine2 = undefined;
          }
        });
      this.Process = currentState;
    }
}
