<div class="container">
  <div id="sidenav" class="sidenav">
    <app-sidenav-list (closeMenu)="close()"></app-sidenav-list>
  </div>
  <div class="content">
    <div class="overlay" *ngIf="menuOpen" (click)="close()"></div>
    <main>
      <app-header (sidenavToggle)="open()"></app-header>
      <div class="main-body">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </main>
  </div>
</div>