import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { Member } from "../models/member";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Eligibility } from '../models/eligibility';

const API_URL = environment.apimBase +'/members';
@Injectable()
export class MemberService {

  constructor(private http: HttpClient, private router: Router, private storage: StorageService) { }

  public ApiGetFamilyMembers(memberUniqueKey: number): Observable<Member[]> {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.storage.accessToken);
    return this.http.get<Member[]>(`${API_URL}/v1/members/${memberUniqueKey}/family`, {headers})
      .pipe(catchError(error => {
        if (error.status === 500) {
          this.router.navigate(['/error']);
        }
        return throwError(error);
      }));
  }

  public getEligibility(memberUniqueKey: number, eligibilityCategory: string): Observable<Eligibility[]> {
    const headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.storage.accessToken);
    const defaultElligibility = {
      activeIndicator: true,
      category: {
        code: '',
        description: ''
      },
      classId: '',
      classPlanId: '',
      deliveryMethodCode: '',
      deliveryMethodName: '',
      effectiveDate: new Date(),
      eligibleIndicator: true,
      groupId: '',
      productDesc: '',
      productId: '',
      productShortName: '',
      subgroupId: '',
      termDate: new Date(),
      billEligibleIndicator: true
    } as Eligibility;

    return this.http.get<Eligibility[]>(`${API_URL}/v1/members/${memberUniqueKey}/eligibility/${eligibilityCategory}`, {headers})
      .pipe(
        catchError(error => {
          if (error.status === 500) {
            //If service is down return default
            return of([defaultElligibility]);
          }
          return throwError(error);
        })
      );
  }

}
