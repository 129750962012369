import { environment } from 'src/environments/environment';
const API_URL = environment.urlBase;
const APIM_BASE = environment.apimBase;
// routes that don't need a jwt added to
// the library will only add a jwt if there is a jwt
// so our pre-auth endpoints do not need to be added here
export const BLACKLISTEDROUTES: Array<any> = [
  API_URL + '/v1/amwellregistration/?memberUniqueKey=*',
  APIM_BASE + '/v1/amwellregistration/?memberUniqueKey=*'
];
