import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionValues } from './session-values.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {

  public storageKeySubject: BehaviorSubject<any>;

  constructor() {
    this.storageKeySubject = new BehaviorSubject<any>('');
    window.addEventListener('storage', this.handleStorageEvent, false);

    this.requestSyncSessionStorage();
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent, false);
  }

  handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === 'requestSyncSessionStorage') {
      //console.log('handleStorageEvent - requestSyncSessionStorage', event);
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'sessionStorage') {
      //console.log('handleStorageEvent - sessionStorage', event);
      try {
        const sessionStorage = JSON.parse(event.newValue || '{}');
        for (const key in sessionStorage) {
          window.sessionStorage.setItem(key, sessionStorage[key]);
        }
      } catch (e) {
          // squash JSON parse error
      }
    }
  };

  requestSyncSessionStorage(): void {
    //console.log('requestSyncSessionStorage - sessionStorage', sessionStorage);
    if (!sessionStorage.length) {
      const current = new Date().toLocaleTimeString();
      //console.log('requestSyncSessionStorage - request', current);
      localStorage.setItem(
        'requestSyncSessionStorage',
        'request session storage' + current
      );
    }
  }

  /*Local Storage */
  private getLocalString(name: string): string | null {
    return localStorage.getItem(name);
  }

  private setLocalString(name: string, value: string) {
    localStorage.setItem(name, value);
  }
  private removeLocalItem(name: string): void {
    localStorage.removeItem(name);
  }

  public get rememberedUser(): any {
    return this.getLocalString(SessionValues.REMEMBERED_USER);
  }

  public ClearRememberedUser() {
    this.removeLocalItem(SessionValues.REMEMBERED_USER);
  }

  public set rememberedUser(username: any) {
    this.setLocalString(SessionValues.REMEMBERED_USER, username);
  }

  /*Session Storage */
  private getSessionString(name: string): string | null {
    return sessionStorage.getItem(name);
  }

  private setSessionString(name: string, value: string) {
    sessionStorage.setItem(name, value);
  }

  private removeSessionItem(name: string): void {
    sessionStorage.removeItem(name);
  }

  // stores the user jwt token in session storage
  public set accessToken(token: any) {
    this.setSessionString(SessionValues.ACCESS_TOKEN, token);
  }

  // gets the user jwt from session storage
  public get accessToken() {
    return this.getSessionString(SessionValues.ACCESS_TOKEN);
  }

  // stores the user jwt token in session storage
  public set refreshToken(token: any) {
    this.setSessionString(SessionValues.REFRESH_TOKEN, token);
  }

  // gets the user jwt from session storage
  public get refreshToken() {
    return this.getSessionString(SessionValues.REFRESH_TOKEN);
  }

  public set sdkInstanceState(sdkInstance: any) {
    this.setSessionString(SessionValues.SDK_INSTANCE_STATE, sdkInstance);
  }

  public get sdkInstanceState() {
    return this.getSessionString(SessionValues.SDK_INSTANCE_STATE);
  }

  public set sdkAuthentication(authentication: any) {
    this.setSessionString(SessionValues.SDK_AUTHENTICATION, authentication);
  }

  public get sdkAuthentication() {
    return this.getSessionString(SessionValues.SDK_AUTHENTICATION);
  }

  public set sdkLoggedOnConsumer(consumer: any) {
    this.setSessionString(SessionValues.SDK_LOGGED_ON_CONSUMER, consumer);
  }

  public get sdkLoggedOnConsumer() {
    return this.getSessionString(SessionValues.SDK_LOGGED_ON_CONSUMER);
  }

  public set dependents(dependents: any) {
    this.setSessionString(SessionValues.DEPENDENTS, dependents);
  }

  public get dependents() {
    return this.getSessionString(SessionValues.DEPENDENTS);
  }

  public set b2cAuth(auth: any) {
    this.setSessionString(SessionValues.B2CAuth, auth);
  }

  public get b2cAuth() {
    return this.getSessionString(SessionValues.B2CAuth);
  }

  public clearUserSessionValues() {
    this.removeSessionItem(SessionValues.SDK_AUTHENTICATION);
    this.removeSessionItem(SessionValues.SDK_LOGGED_ON_CONSUMER);
    this.removeSessionItem(SessionValues.ACCESS_TOKEN);
    this.removeSessionItem(SessionValues.REFRESH_TOKEN);
    this.removeSessionItem(SessionValues.DEPENDENTS);
    this.removeSessionItem(SessionValues.LINK_LOGIC_ROLES);
    this.removeSessionItem(SessionValues.B2CAuth);
    this.removeSessionItem(SessionValues.ID_TOKEN);
    localStorage.clear();
  }

  public set visitProcess(ucProcess: any) {
    this.setSessionString(SessionValues.UCProcess, ucProcess);
  }

  public get visitProcess() {
    return this.getSessionString(SessionValues.UCProcess);
  }

  public resetProcess() {
    this.removeSessionItem(SessionValues.UCProcess);
  }

  public resetVisit() {
    this.removeSessionItem(SessionValues.VISIT);
  }

  public get visitInProgress() {
    return this.getSessionString(SessionValues.VISIT);
  }

  public set visitInProgress(visit: any) {
    this.setSessionString(SessionValues.VISIT, visit);
  }

  // stores the user jwt token in session storage
  public set linkLogicRoles(token: any) {
    this.setSessionString(SessionValues.LINK_LOGIC_ROLES, token);
  }

  // gets the user jwt from session storage
  public get linkLogicRoles() {
    return this.getSessionString(SessionValues.LINK_LOGIC_ROLES);
  }

  public set IdToken(token: string) {
    this.setSessionString(SessionValues.ID_TOKEN, token);
  }

  public get IdToken() {
    return this.getSessionString(SessionValues.ID_TOKEN)!;
  }
}
