import { LoggingService } from 'src/app/logging/logging.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import awsdk from '@bluekc/awsdk';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { DoctorsService } from '../../services/doctors.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.css']
})
export class DoctorProfileComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> {

  public Doctor!: awsdk.AWSDKProvider;
  public DoctorInfo!: awsdk.AWSDKProviderDetails;
  public Languages = '';
  public DisableNextButton = true;

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    public doctorsService: DoctorsService,
    public snackBar: MatSnackBar,
    public logging: LoggingService) {

    super(process, router, location, sdk, logging );

    this.Doctor = this.doctorsService.SelectedDoctor;
    this.GetDoctorDetails();

  }

  private GetDoctorDetails() {
    this.sdk.getProvidersWithDetails(this.doctorsService.SelectedDoctor)
      .then(
        (details) => {
          this.DoctorInfo = details;
          this.Languages = details.spokenLanguages.map((language) => language.value).join();
          this.DisableNextButton = false;
        }
      ).catch(() => {
        this.DisableNextButton = true;
        this.snackBar.open("There was an error loading virtual care provider information. To continue, please try again.", "Retry")
        .onAction().subscribe( () => {
          this.GetDoctorDetails();
        });
      });
  }

  public get spokenLanguages(): string {
    let languages = "";
    this.DoctorInfo?.spokenLanguages?.forEach(x => {
      languages = languages + " " + x.value;
    });

    return languages;
  }
  public NextStep() {

    try {

      let currentProcess = this.process.Process;

      // make sure doctors exists in the process
      let doctorsValue = this.process.Process['doctors'];
      if (doctorsValue === null)
        return;

      // make sure the doctor property is the expected type
      let doctor = doctorsValue as ProcessStep<awsdk.AWSDKProvider | awsdk.AWSDKOnDemandSpecialty | null>;
      if (doctor === null)
        return;

      currentProcess['doctors'].Value = this.Doctor;
      currentProcess['doctors'].DisplayValue = this.Doctor.fullName;

      super.SaveAndGo(currentProcess);
    }
    catch (error: any) {
      console.log("Failed to update selected doctor: " + error.message);
    }

  }


}
