import { LoggingService } from 'src/app/logging/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MedicationSelection } from './MedicationSelection';
import awsdk from '@bluekc/awsdk';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import { ProcessStep } from 'src/app/processes/ProcessStep.interface';
import { ProcessServiceInterface } from '../../services/process-service.interface';
@Component({
  selector: 'app-current-meds',
  templateUrl: './current-meds.component.html',
  styleUrls: ['./current-meds.component.css'],
})
export class CurrentMedsComponent
  <T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T>

  implements OnInit {
  SearchText = '';
  Searching: boolean = false;
  NoSearchResults: boolean = false;
  SelectedMedicenes: Array<MedicationSelection> = [];
  GhostList = [1, 2, 3, 4, 5];
  Loading: boolean = false;

  DisplayResults: Array<MedicationSelection> = [];
  constructor(
    public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public sdkService: AmwellSDKService,
    private patient: awsdk.AWSDKConsumer | null,
    public snackBar: MatSnackBar,
    public logging: LoggingService
  ) {
    super(process, router, location, sdkService, logging);
  }

  ngOnInit() {
    this.GetPatientMedications();
  }

  public async GetPatientMedications() {
    this.Loading = true;
    if (this.patient === null)
      return;

    try {
      let meds = await this.sdkService.getPatientMedications(this.patient);
      if (meds) {
        this.SelectedMedicenes = meds.medications.map(m => {
          return {
            Medication: m,
            Selected: true
          }
        });
        this.DisplayResults = this.SelectedMedicenes;
      }
    } catch (error) {
      //TODO: logging?
      this.snackBar.open('An error has occurred. We cannot access your existing list of medications.', 'Retry')
      .onAction().subscribe(async () => {
        this.GetPatientMedications();
      });
    }

    this.Loading = false;
  }

  public async NextStep() {
    try {
      let currentProcess = this.process.Process;

      let mappedMedicines = this.SelectedMedicenes.map(m => {
        return m.Medication;
      });

      if (this.patient !== null) {
        let updated = await this.sdkService.updatePatientMedications(this.patient, mappedMedicines);
        currentProcess.medications.Value = updated;
        if (mappedMedicines.length > 0) {
          currentProcess.medications.DisplayValue = mappedMedicines.map(s => { return s.displayName }).join('; ');
        }
        else {
          currentProcess.medications.DisplayValue = "None";
        }
      }
      //skip vitals if aged more than 18
      if (this.patient?.age && +this.patient?.age >= 18) {
        currentProcess.vitals.Value = true;
      }
      super.SaveAndGo(currentProcess);
    }
    catch (error) {
      this.snackBar.open('An error has occurred. We weren’t able to update your medications. Use the list above to try again.');
    }
  }

  public async Search() {
    this.Loading = true;
    this.NoSearchResults = false;
    this.Searching = true;
    let searchText = this.SearchText.toUpperCase();

    if (searchText.length >= 3) {
      let patient = this.process.Process.patient as ProcessStep<awsdk.AWSDKConsumer>;
      if (patient.Validation()) {
        try {
          let results = await this.sdkService.searchMedication(patient.Value!, searchText);
          if (results) {
            let searchResults = results.medications.filter(d => d.displayName.toUpperCase().indexOf(searchText) >= 0);

            if (searchResults.length === 0) {
              this.NoSearchResults = true;
            }
            // remove any items from searchResults that are already Selected
            searchResults = searchResults.filter(r => !this.SelectedMedicenes.some(s => s.Medication.displayName === r.displayName));
            let mappedResults = searchResults.map(i => {
              return {
                Medication: i,
                Selected: false
              } as MedicationSelection;
            });
            // get selected meds that are still selected
            // this will get rid of items the user has deselected
            let stillSelected = this.SelectedMedicenes.filter(s => s.Selected === true);

            // combine the two datasets for display and put selected at the end of the list
            this.DisplayResults = mappedResults.concat(stillSelected);
            this.Searching = false;
          }
          else {
            this.NoSearchResults = true;
          }
        }
        catch (error) {
          this.snackBar.open('An error has occurred. Search results are not available.', 'Retry')
          .onAction().subscribe(async () => {
            this.Search();
          });
        }
      }
    }
    else {
      this.DisplayResults = this.SelectedMedicenes;
      this.Searching = false;
    }
    this.Loading = false;
  }

  public SelectItem(med: awsdk.AWSDKMedication) {
    //Update display
    this.addMedToList(med);
    //Save
    let patient = this.process.Process.patient as ProcessStep<awsdk.AWSDKConsumer>;
    if (patient.Validation()) {
      this.updateThroughSDK(patient!.Value, med,true);
    }
  }

  public RemoveItem(med: awsdk.AWSDKMedication) {
    //Update display
    this.removeMedFromList(med);
    //Save
    let patient = this.process.Process.patient as ProcessStep<awsdk.AWSDKConsumer>;
    if (patient.Validation()) {
      this.updateThroughSDK(patient!.Value, med, false);
    }
  }

  private addMedToList(med: awsdk.AWSDKMedication) {
    let theItem = this.DisplayResults.find(r => r.Medication.displayName === med.displayName);

    if (theItem === undefined)
      return;

    // since the item here comes from the search set and the search set holds references to the original array, this will
    // update the value on the original array (pass by ref)
    theItem.Selected = true;

    // add item to selected; it won't display from this set until the next search
    // so the item stays in it's position on the screen
    this.SelectedMedicenes.push(theItem);
  }

  private removeMedFromList(med: awsdk.AWSDKMedication) {
    // toggle value of item in current set
    let theItem = this.DisplayResults.find(r => r.Medication.displayName === med.displayName);

    if (theItem === undefined)
      return;

    // if the item came from the search array this will update it by referece
    // if the item came from SelectedMedicenes, it does not reference the array so
    // we have to do that manually
    theItem.Selected = false;

    let theItemInSelectedArray = this.SelectedMedicenes.find(r => r.Medication.displayName === med.displayName);

    // nooop we're all good so lets go
    if (!theItemInSelectedArray)
      return;

    // remove from selected array but also update the base lis
    // remove item from selected meds; item will still display until next search
    this.SelectedMedicenes = this.SelectedMedicenes.filter(s => s.Medication.displayName !== med.displayName);

    let theOriginalItem = this.DisplayResults.find(r => r.Medication.displayName === med.displayName);

    if (!theOriginalItem)
      return;

    theOriginalItem.Selected = false;

    this.SelectedMedicenes = this.SelectedMedicenes.filter(
      (s) => s.Medication.displayName !== med.displayName
    );
  }

  private updateThroughSDK(patient: awsdk.AWSDKConsumer,
    med: awsdk.AWSDKMedication,
    isAdd: boolean) {
    let mappedMedicines = this.SelectedMedicenes.map(m => {
      return m.Medication;
    });
    this.sdkService
      .updatePatientMedications(patient, mappedMedicines)
      .catch(() => {
        if (isAdd) {
          //Add failed so remove Item
          this.removeMedFromList(med);
        }
        else {
          //Remvoe failed so readd
          this.addMedToList(med);
        }
        this.snackBar.open('An error has occurred. We weren’t able to update your medications. Use the list above to try again.');
      });
  }
}
