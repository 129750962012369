import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.apimBase;
@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(private http: HttpClient, private router: Router) { }

  public SsoStandard( method: string): Observable<Object> {
    return this.http.get(API_URL + '/v1/sso/' + method, { responseType: 'text'})
    .pipe(catchError(error => {
      if (error.status === 500) {
        this.router.navigate(['/error']);
      }
      return throwError(error);
    }));
  }
}
