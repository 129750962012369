
import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  handleError(error: Error) {
    // Handle chunking error
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    this.appInsightsService.logException(error); // Manually log exception
  }
}
