import { LoggingService } from 'src/app/logging/logging.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessBaseComponent } from '../process-base/process-base.component';
import { Location } from '@angular/common';
import { AmwellSDKService } from 'src/app/shared/services/amwell-sdkservice.service';
import { PreferencesService } from 'src/app/shared/services/preferences.service';
import awsdk from '@bluekc/awsdk';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProcessServiceInterface } from '../../services/process-service.interface';
import { ProcessStep } from '../../../processes/ProcessStep.interface';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})

export class SelectLocationComponent<T extends Record<string, ProcessStep<unknown>>> extends ProcessBaseComponent<T> implements OnInit {
  public ShowErrorText = false;
  public StateCode = "";
  private newState: awsdk.AWSDKState | undefined = undefined;
  private amwellStates: awsdk.AWSDKState[] = [];
  private consumer: awsdk.AWSDKConsumer | undefined = undefined;

  constructor(public process: ProcessServiceInterface<T>,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    public amwellSdkService: AmwellSDKService,
    public preferencesService: PreferencesService,
    public snackBar: MatSnackBar,
    public logging: LoggingService) {
    super(process, router, location, amwellSdkService, logging);
  }

  ngOnInit() {
    this.amwellSdkService.getStates().then(res => {
      if(res !== undefined)
        this.amwellStates = res;
    });

    this.consumer = this.amwellSdkService.getConsumer();
    this.StateCode = this.consumer?.legalResidence?.code;
  }

  public async NextStep() {
    if (this.StateCode === "")
      return;

    if(this.newState !== undefined) {
      try {
        if(!await this.preferencesService.UpdateLocationPreference(this.newState)) {
          this.snackBar.open('Oops, your location could not be saved. Please try again.');
          return;
        }
      } catch(e) {
        this.logging.LogError('SelectLocationComponent','Failed saving location preferences', e as awsdk.AWSDKError, this.consumer!);
        this.snackBar.open('Oops, your location could not be saved. Please try again.');
        return;
      }
    }

    let currentProcess = this.process.Process;

    this.amwellSdkService.getStates().then(states => {
      let selectedState = states?.find(state => state.code === this.StateCode);
      this.process.resetSteps('select-location');
      if (selectedState) {
        currentProcess['select-location'].Value = selectedState;
        currentProcess['select-location'].DisplayValue = selectedState!.name;
        super.SaveAndGo(currentProcess);
      } else {
        this.logging.LogGenericConsumerError('SelectLocationComponent','Failed getting states', this.consumer!)
      }
    });
  }

  prevStep() {
    this.router.navigate(['enable-location'], { relativeTo: this.route.parent });
  }

  OnSelectState(event: string) {
    this.StateCode = event;
    this.newState = this.amwellStates.find(state => state.code === event);
  }

}
