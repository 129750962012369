import { HttpParameterCodec } from '@angular/common/http';

// https://github.com/angular/angular/issues/18261
// As of 12/17 Angular does not support encoding the + character.
// This is a custom encoder class that uses the browsers encoder to
// encode HttpParams correctly
export class Encoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
