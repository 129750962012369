import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import awsdk from '@bluekc/awsdk';
import { Location } from '@angular/common';
import { AmwellSDKService } from '../shared/services/amwell-sdkservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoggingService } from '../logging/logging.service';

@Component({
  selector: 'app-behavioral-health-appointments',
  templateUrl: './behavioral-health-appointments.component.html',
  styleUrls: ['./behavioral-health-appointments.component.css']
})
export class BehavioralHealthAppointmentsComponent implements OnInit {
  private subscriber!: awsdk.AWSDKConsumer;
  public dependants: awsdk.AWSDKConsumer[] | undefined;
  public consumerAppointments!: awsdk.AWSDKAppointment[];
  public appointmentForm: FormGroup;
  public selectedConsumer!: awsdk.AWSDKConsumer;
  public loadingAppointments: boolean = false;

  constructor(private fb: FormBuilder,
    public router: Router,
    public location: Location,
    public sdk: AmwellSDKService,
    private snackBar: MatSnackBar,
    private logging: LoggingService) {
    this.appointmentForm = this.fb.group({
      dependent: [null]
    });
  }

  public async ngOnInit() {
    this.subscriber = this.sdk.getConsumer();
    this.logging.LogUserAction('BehavioralHealthAppointments', 'User navigates to Scheduled Appointments',this.subscriber);
    this.LoadPage();
  }

  private async LoadPage() {
    this.loadingAppointments = true;
    if (this.subscriber) {
      try {
        this.dependants = await this.sdk.getDependents();
      } catch (exception) {
        this.dependants = [];
        this.logging.LogError('BehavioralHealthAppointments', 'error calling getDependents', exception as awsdk.AWSDKError, this.subscriber);
        this.snackBar.open('Oops, an error has occurred. To display the dependents on your policy, please try again.', 'Retry')
          .onAction().subscribe(async () => {
            this.dependants = undefined;
            this.LoadPage();
          });
      }
      if (this.dependants?.includes(this.subscriber)) {
        this.dependants = this.dependants.filter(dependent => dependent !== this.subscriber);
      }
      // Current subscriber at the top of array
      this.dependants?.unshift(this.subscriber);

      this.selectedConsumer = this.subscriber;
      this.consumerAppointments = await this.getConsumerAppointments(this.subscriber);

      this.appointmentForm.patchValue({
        dependent: this.subscriber.id.persistentId
      });
      this.loadingAppointments = false;
    }
  }

  private async getConsumerAppointments(consumer: awsdk.AWSDKConsumer): Promise<awsdk.AWSDKAppointment[]> {
    try {
      const appointments = await this.sdk.getAppointments(consumer);
      return appointments.filter(appoint => appoint.consumer.id.persistentId === consumer.id.persistentId);
    } catch (exception) {
      this.logging.LogError('BehavioralHealthAppointments', 'error calling getAppointments',exception as awsdk.AWSDKError, consumer);
      this.snackBar.open('Oops, an error has occurred. We cannot access your scheduled appointments.', 'Retry')
        .onAction().subscribe(async () => {
          this.loadingAppointments = true;
          this.consumerAppointments = await this.getConsumerAppointments(consumer);
          this.loadingAppointments = false;
        });
      return [];
    }
  }

  public async onDependentChange() {
    const consumer = this.dependants?.find(x => x.id.persistentId === this.appointmentForm.get("dependent")!.value);
    this.selectedConsumer = consumer!;
    this.consumerAppointments = [];
    this.loadingAppointments = true;
    this.consumerAppointments = await this.getConsumerAppointments(consumer!);
    this.loadingAppointments = false;
  }

  public Back(): void {
    this.location.back();
  }
}
